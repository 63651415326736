/* eslint-disable indent */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Scope } from '@unform/core'
import {
  FormUnform,
  Accordion,
  Botao,
  Breadcrumb,
  Cabecalho,
  focarPrimeiroCampoOuEditorComErro,
  IconeAdicionar,
  IconeBack,
  IconeLixeira,
  IconeInformacao,
  Link,
  Modal,
  ModalRef,
  ModalVincular,
  Navegacao,
  SelectOpcao,
  SeletorUnform,
  SubTitulo,
  InputUnform,
  MoedaInputUnform,
  NumeroInputUnform,
  Tooltip,
  Spacer,
  IconeCopiar,
  TextAreaUnform,
  ConteudoBotao,
  formatarValorMoeda,
  IconeEditar,
  CheckboxUnform,
  CalendarioUnform,
  ModalExcluirMaterialRef,
  ModalExcluirMaterial,
  IconeDeEdicaoDeUpload
} from 'src/componentes'
import { CampoLabel } from 'src/componentes/input/styles'
import { ModalVincularRef } from 'src/componentes/modal-vincular-disciplina/tipos'
import {
  ListaPerguntas,
  ModalIncluirPergunta,
  ModalIncluirPerguntaRef
} from 'src/componentes/perguntas'
import { EditorUnform } from 'src/componentes/unform/editor'
import { ArquivoInputUnform } from 'src/componentes/unform/input/arquivo'
import { SelectAsyncUnform, SelectUnform } from 'src/componentes/unform/select'
import { Avaliacao, CursoLivre, CursoPayload, Pergunta } from 'src/dto'
import { RotasAcademico } from 'src/rotas'
import { Api } from 'src/servicos'
import {
  Curso,
  CursoSituacao,
  Disciplina,
  ModalidadeCurso,
  PlanoPagamentoParcelas,
  TCCSituacao,
  TipoAvaliacao,
  FormaPagamentoCobranca,
  TipoDisciplina,
  ModalidadeLivre,
  TipoPesquisaSatisfacao,
  PlanoPagamentoProrrogacao,
  validaEhModalidadeLivreOuEvento,
  validaEhModalidadeLivre,
  FormaPagamentoPorExtenso,
  PlanoPagamentoReingresso,
  ModalidadeCursoPorExtenso
} from 'src/tipos'
import { ModoPaginaCadastro } from 'src/tipos/genericos'
import { v4 } from 'uuid'

import { DesvincularDisciplina } from '../cadastro/componentes/desvincular-disciplina'
import {
  ModalCopiar,
  ModalCopiarGradeRef,
  ModalIncluirGrade,
  ModalIncluirGradeRef,
  ModalIncluirModulo,
  ModalIncluirPlanoReingressoRef,
  ModalIncluirProrrogacao,
  ModalIncluirProrrogacaoRef,
  ModalIncluirTesteRef
} from './componentes'
import { DesvincularDisciplinaCursoLivre } from './componentes/desvincular-disciplina-curso-livre'
import { ListaDisciplina } from './componentes/lista-disciplinas'
import { ModalEditarModulo } from './componentes/modal-editar-modulo'
import { ModalEditarModuloRef } from './componentes/modal-editar-modulo/tipos'
import { ModalIncluirModuloRef } from './componentes/modal-incluir-modulo/tipos'
import { ModalIncluirParcela } from './componentes/modal-incluir-parcela'
import { ModalIncluirPlanoReingresso } from './componentes/modal-incluir-plano-reingresso'
import { criarSchema } from './schema'
import {
  Container,
  ContainerMain,
  ContainerDadosMedio,
  Conteudo,
  ContainerDados,
  ContainerDadosPequeno,
  ContainerNavegacao,
  Secao,
  ContainerAcoes,
  ContainerBotaoIcone,
  ContainerDisciplinas,
  BotaoIcone,
  ContainerIcone,
  ConteudoGrade,
  ContainerBotao,
  ContainerGrade,
  TituloModulo,
  FormContainer,
  ContainerLink,
  SecaoPrimaria,
  Tabela,
  OpcaoDados,
  Opcao,
  Linha,
  ListaModulos,
  SeloFlex,
  ConteudoBotaoEditarModulo
} from './styles'
import {
  PaginaCursosProps,
  GradeCurricular,
  DisciplinaVinculada,
  DisciplinaDraggable,
  ModalExcluirDisciplinaRef
} from './tipos'

const PaginaCursos: React.FC<PaginaCursosProps> = ({ match, modo }) => {
  const [titulo] = useState(
    `${modo === ModoPaginaCadastro.Inclusao ? 'Inclusão' : 'Edição'} de Curso`
  )
  const [curso, definirCurso] = useState<CursoPayload | undefined>(undefined)
  const [parcelas, setParcelas] = useState<
    CursoPayload['planoPagamento'] | undefined
  >(undefined)
  const [prorrogacoes, definirProrrogacoes] = useState<
    PlanoPagamentoProrrogacao[]
  >([])

  const [
    planosPagamentoReingresso,
    definirPlanosPagamentoReingresso
  ] = useState<PlanoPagamentoReingresso[]>([])

  const [pronto, definirPronto] = useState(false)

  enum Navegacoes {
    DadosCurso = 'dados-curso',
    InformacoesFinanceiras = 'informacoes-financeiras',
    InformacoesIniciasCurso = 'informacoes-iniciais-curso',
    InformacoesCurso = 'informacoes-curso',
    Disciplinas = 'disciplinas',
    Avaliacoes = 'avaliacoes',
    GradeCurricular = 'grade-curricular',
    VersoCertificado = 'verso-certificado',
    PesquisaSatisfacao = 'pesquisa-satisfacao'
  }

  const cursoDefault = {
    situacao: CursoSituacao[CursoSituacao.Ativo],
    tcc: TCCSituacao.Nao
  } as Curso

  const modalRef = useRef<ModalRef>(null)
  const modalPerguntaRef = useRef<ModalIncluirPerguntaRef>(null)
  const modalModuloRef = useRef<ModalIncluirModuloRef>(null)
  const modalEditarModuloRef = useRef<ModalEditarModuloRef>(null)
  const modalModuloTCCRef = useRef<ModalIncluirModuloRef>(null)
  const modalExcluirMaterialRef = useRef<ModalExcluirMaterialRef>(null)
  const [carregando, definirCarregando] = useState(false)
  const history = useHistory()
  const { state } = useLocation<{ modalidade?: ModalidadeCurso }>()
  const [modalidadeCurso, definirModalidade] = useState<string>(
    state?.modalidade ?? match.params.modalidade
  )
  const [
    disciplinasVinculadasDraggable,
    definirDisciplinasVinculadasDraggable
  ] = useState<DisciplinaVinculada[]>([])
  const [disciplinasVinculadasTCC, definirVinculadasTCC] = useState<
    DisciplinaVinculada[]
  >([])
  const [valorParcela, setValorParcela] = useState('')
  const [quantidade, setQuantidadeParcelas] = useState('')
  const [precoBase, setPrecoBase] = useState('')
  const valorFormatado = formatarValorMoeda(valorParcela)
  const precoBaseFormatado = formatarValorMoeda(precoBase)
  const modalDisciplinaRef = useRef<ModalRef>(null)
  const modalDesvincularDisciplinaref = useRef<ModalVincularRef>(null)
  const modalDesvincularDisciplinaCursoLivreRef = useRef<ModalExcluirDisciplinaRef>(
    null
  )
  const modalIncluirGradeRef = useRef<ModalIncluirGradeRef>(null)
  const modalIncluirParcelaRef = useRef<ModalIncluirTesteRef>(null)
  const modalIncluirProrrogacaoRef = useRef<ModalIncluirProrrogacaoRef>(null)
  const modalCopiarGradeRef = useRef<ModalCopiarGradeRef>(null)
  const modalIncluirPlanoReingresso = useRef<ModalIncluirPlanoReingressoRef>(
    null
  )
  const [gradesCurriculares, definirGradesCurriculares] = useState<
    GradeCurricular[]
  >([])
  const [exibirGrade, defineExibirGrade] = useState(false)
  const [existeGrade, defineExisteGrade] = useState(false)
  const [perguntas, definirPerguntas] = useState<Pergunta[]>([])
  const [avaliacao, definirAvaliacao] = useState<Avaliacao>()
  const [quantidadeTentativas, definirQuantidadeTentativas] = useState<string>(
    null
  )
  const [descricao, definirDescricao] = useState<string>(null)
  const [temGrupoDiscussao, definirTemGrupoDiscussao] = useState(false)
  const [grupoDiscussaoDescricao, definirGrupoDiscussaoDescricao] = useState('')
  const [
    formaPagamentoEdicao,
    definirFormaPagamentoEdicao
  ] = useState<FormaPagamentoCobranca>(null)
  const [cursoPossuiTCC, definirCursoPossuiTCC] = useState<boolean>(false)
  const [
    tempoMaximoProrrogacao,
    definirTempoMaximoProrrogacao
  ] = useState<number>()
  const [tempoProrrogacao, definirTempoProrrogacao] = useState<string>('')
  const [valorProrrogacao, definirValorProrrogacao] = useState<string>('')
  const [
    qtdParcelasProrrogacao,
    definirQtdParcelasProrrogacao
  ] = useState<string>('')
  const [possuiAvaliacao, definirPossuiAvaliacao] = useState(false)
  const [adicionarAvaliacao, definirAdicionarAvaliacao] = useState(false)
  const [
    quantidadeParcelasReingresso,
    definirQuantidadeParcelasReingresso
  ] = useState(null)
  const [
    formaPagamentoReingresso,
    definirFormaPagamentoReingresso
  ] = useState<FormaPagamentoCobranca>(null)

  const [erroAccordion, definirErroAccordion] = useState(false)
  const valorProrrogacaoFormatado = formatarValorMoeda(valorProrrogacao)
  const modalDisciplinaTCCRef = useRef<ModalRef>(null)
  const modalDesvincularDisciplinaTCCref = useRef<ModalVincularRef>(null)

  const ehCursoLivreOuEvento = useMemo(
    () => validaEhModalidadeLivreOuEvento(ModalidadeCurso[modalidadeCurso]),
    [modalidadeCurso]
  )

  const ehCursoLivre = useMemo(
    () => validaEhModalidadeLivre(ModalidadeCurso[modalidadeCurso]),
    [modalidadeCurso]
  )

  const obterGradeAtiva = () =>
    gradesCurriculares.find(x => x.situacao === 'Ativo')

  const obterGradesInativas = () =>
    gradesCurriculares.filter(x => x.situacao === 'Inativo')

  const comparadorDeGrades = (
    atual: GradeCurricular,
    proximo: GradeCurricular
  ): number => {
    const condicaoDeEmpate = new Array<boolean>(
      atual.situacao === 'Ativo' && proximo.situacao === 'Ativo',
      atual.situacao === 'Inativo' && proximo.situacao === 'Inativo'
    ).some(condicao => condicao)

    if (condicaoDeEmpate) {
      return 0
    } else {
      const ordenacao =
        atual.situacao === 'Ativo' && proximo.situacao === 'Inativo'

      return ordenacao ? -1 : 1
    }
  }

  const atualizarGradeAtiva = (gradeAtiva: GradeCurricular) => {
    const grades = [gradeAtiva, ...obterGradesInativas()].sort(
      comparadorDeGrades
    )
    definirGradesCurriculares(grades)
  }

  const adicionarGrade = (gradeAtiva: GradeCurricular) => {
    const gradesInativas = obterGradesInativas()
    const grade = obterGradeAtiva()

    if (grade) {
      grade.situacao = 'Inativo'
      const grades = [gradeAtiva, grade, ...gradesInativas].sort(
        comparadorDeGrades
      )
      definirGradesCurriculares(grades)
      return
    }

    const grades = [gradeAtiva, ...gradesInativas].sort(comparadorDeGrades)
    definirGradesCurriculares(grades)
  }

  const adicionarModulo = modulo => {
    const gradeAtiva = obterGradeAtiva()
    const gradeAlterada = {
      ...gradeAtiva,
      modulos: [...(gradeAtiva.modulos ?? []), { ...modulo, disciplinas: [] }]
    }
    atualizarGradeAtiva(gradeAlterada)
  }

  const editarModulo = modulo => {
    const gradeAtiva = obterGradeAtiva()

    const gradeAlterada = gradeAtiva.modulos.map(element => {
      if (element.nome === modulo?.nome) {
        element.tipoModulo = modulo?.tipoModulo
        element.informacoesAdicionais = modulo?.informacoesAdicionais
      }
      return element
    })
    gradeAtiva.modulos = gradeAlterada

    atualizarGradeAtiva(gradeAtiva)
  }

  const adicionarModuloTCC = modulo => {
    const gradeAtiva = obterGradeAtiva()
    const gradeAlterada = {
      ...gradeAtiva,
      moduloTCC: modulo
    }

    atualizarGradeAtiva(gradeAlterada)
  }

  const abrirModalAdicionarPergunta = (pergunta: Pergunta) =>
    modalPerguntaRef?.current?.abrir(pergunta)

  const adicionarPergunta = (pergunta: Pergunta) => {
    const novaPergunta = {
      ...pergunta,
      id: pergunta.id ?? v4()
    }
    const perguntaIndex = perguntas.findIndex(x => x.id === pergunta?.id)

    if (perguntaIndex >= 0) {
      const editadas = [...perguntas]
      editadas.splice(perguntaIndex, 1, novaPergunta)
      definirPerguntas(editadas)
    } else {
      definirPerguntas([...perguntas, novaPergunta])
    }
  }

  const removerPergunta = (pergunta: Pergunta) =>
    definirPerguntas(perguntas.filter(x => x.id !== pergunta.id))

  const [indexModulo, defineIndex] = useState(null)
  const PaginaEmModoEdicao = modo === ModoPaginaCadastro.Edicao

  const modalidadeAlterada = (opcao: SelectOpcao) => {
    definirModalidade(opcao?.id)
    if (modo === ModoPaginaCadastro.Inclusao) {
      definirGradesCurriculares([])
      defineExibirGrade(false)
      defineExisteGrade(false)
    }
  }

  const abrirModalExcluirDisciplina = (
    disciplina: Disciplina,
    indexModulo: number
  ) => {
    modalDesvincularDisciplinaref?.current?.abrir(disciplina, indexModulo)
  }

  const abrirModalExcluirDisciplinaTCC = (disciplina: Disciplina) => {
    modalDesvincularDisciplinaTCCref?.current?.abrir(disciplina)
  }

  const isModalidadeOutra = () => !ehCursoLivreOuEvento && !!modalidadeCurso

  const isModalidadePosGraduacao =
    modalidadeCurso === ModalidadeCurso.PosGraduacaoEad ||
    modalidadeCurso === ModalidadeCurso.PosGraduacaoEadFlex ||
    modalidadeCurso === ModalidadeCurso.PosGraduacaoEadFull

  const ehPosGraduacao =
    modalidadeCurso === ModalidadeCurso.PosGraduacaoEad ||
    modalidadeCurso === ModalidadeCurso.PosGraduacaoEadFull ||
    modalidadeCurso === ModalidadeCurso.PosGraduacaoEadFlex ||
    modalidadeCurso === ModalidadeCurso.PosGraduacaoPresencial

  const adicionaDisciplinaClick = (disciplina: Disciplina) => {
    if (!disciplina) {
      toast('Selecione a Disciplina', {
        type: 'info'
      })
      return
    }

    if (
      ehCursoLivreOuEvento &&
      disciplinasVinculadasDraggable.find(
        p => p.id === disciplina.id && !p.deletado
      )
    ) {
      toast('Esta disciplina já foi vinculada!', {
        type: 'info'
      })

      return
    }

    if (isModalidadeOutra()) {
      const gradeAtiva = obterGradeAtiva()
      const vinculadasGradeAtiva = gradeAtiva?.modulos?.flatMap(
        x => x.disciplinas
      )

      if (
        vinculadasGradeAtiva?.some(x => x.id === disciplina.id && !x.deletado)
      ) {
        toast('Esta disciplina já foi vinculada!', {
          type: 'info'
        })

        return
      }

      const modulosAtualizados = gradeAtiva.modulos?.map((item, index) => {
        if (index === indexModulo) {
          item = {
            ...item,
            disciplinas: [
              ...item.disciplinas,
              {
                id: disciplina.id,
                nome: disciplina.nome,
                cargaHoraria: disciplina.cargaHoraria,
                deletado: false
              }
            ]
          }
        }
        return item
      })
      atualizarGradeAtiva({ ...gradeAtiva, modulos: modulosAtualizados })
    }
    definirDisciplinasVinculadasDraggable(old => [
      ...old,
      {
        id: disciplina.id,
        nome: disciplina.nome,
        cargaHoraria: disciplina.cargaHoraria,
        deletado: false,
        draggableId: disciplina.id
      }
    ])
    modalDisciplinaRef?.current?.fechar()
    defineExisteGrade(true)
  }

  const adicinaDisciplinaTCCClick = (disciplina: Disciplina) => {
    if (!disciplina) {
      toast('Selecione a Disciplina', {
        type: 'info'
      })
      return
    }

    if (ehCursoLivreOuEvento) {
      toast('Modalidade inválida', {
        type: 'info'
      })
      return
    }
    if (isModalidadeOutra()) {
      const gradeAtiva = obterGradeAtiva()
      const vinculadasGradeAtiva = gradeAtiva?.moduloTCC?.disciplinas

      if (
        vinculadasGradeAtiva?.some(x => x.id === disciplina.id && !x.deletado)
      ) {
        toast('Esta disciplina já foi vinculada!', {
          type: 'info'
        })

        return
      }

      const moduloTCCAtualizado = {
        ...gradeAtiva?.moduloTCC,
        disciplinas: [
          ...(gradeAtiva?.moduloTCC?.disciplinas ?? []),
          {
            id: disciplina.id,
            nome: disciplina.nome,
            cargaHoraria: disciplina.cargaHoraria,
            deletado: false
          }
        ]
      }

      atualizarGradeAtiva({ ...gradeAtiva, moduloTCC: moduloTCCAtualizado })
    }

    definirVinculadasTCC(old => [
      ...old,
      {
        id: disciplina.id,
        nome: disciplina.nome,
        cargaHoraria: disciplina.cargaHoraria,
        deletado: false
      }
    ])
    modalDisciplinaTCCRef?.current?.fechar()
    defineExisteGrade(true)
  }

  const excluiDisciplinasClick = (
    disciplina: Disciplina,
    indexModulo: number
  ) => {
    if (gradesCurriculares) {
      const gradeCurricular = obterGradeAtiva()

      const modulosAtualizados =
        gradeCurricular?.modulos?.map((item, index) => {
          if (index === indexModulo) {
            item = {
              ...item,
              disciplinas: [
                ...item.disciplinas.map((p: DisciplinaVinculada) => {
                  if (disciplina.id === p.id) {
                    p.deletado = true
                  }
                  return p
                })
              ]
            }
          }
          return item
        }) ?? []

      atualizarGradeAtiva({ ...gradeCurricular, modulos: modulosAtualizados })
    }
  }

  const excluiDisciplinasTCCClick = (
    dado: DisciplinaVinculada[],
    disciplina: Disciplina
  ) => {
    if (gradesCurriculares) {
      const gradeCurricular = obterGradeAtiva()

      const moduloTCCAtualizado = {
        ...gradeCurricular?.moduloTCC,
        disciplinas: [
          ...gradeCurricular?.moduloTCC?.disciplinas?.map(
            (p: DisciplinaVinculada) => {
              if (disciplina.id === p.id) {
                p.deletado = true
              }
              return p
            }
          )
        ]
      }

      atualizarGradeAtiva({
        ...gradeCurricular,
        moduloTCC: moduloTCCAtualizado
      })
    }

    definirVinculadasTCC(
      dado.map((p: DisciplinaVinculada) => {
        if (p.id === disciplina.id) {
          p.deletado = true
        }
        return p
      })
    )
  }

  const dadosIniciais = useMemo(() => {
    return curso || cursoDefault
  }, [curso])

  const carregarPesquisaSatisfacaoTexto = async (
    texto?: string
  ): Promise<SelectOpcao[]> => {
    try {
      const dados = await Api.RequisitarListaPesquisaSatisfacaoCodigo(
        ModalidadeCurso[modalidadeCurso],
        TipoPesquisaSatisfacao.Curso,
        texto
      )
      return dados?.map(d => ({
        id: d.id,
        texto: d.codigo,
        dadosAdicionais: d
      }))
    } catch {
      return []
    }
  }

  const carregarPesquisaSatisfacaoPorId = async (
    id: string
  ): Promise<SelectOpcao[]> => {
    try {
      const dados = await Api.RequisitarPesquisaSatisfacao(id)
      return [{ id: dados.id, texto: dados.codigo }]
    } catch {
      return []
    }
  }

  const acaoSucessoCursoLivre = async (dadosLivre: CursoLivre) => {
    if (dadosLivre.avaliacao?.nome && (perguntas ?? []).length === 0) {
      toast(
        'Ao preencher dados da Avaliação, pelo menos uma pergunta deve ser informada.',
        { type: 'error' }
      )
      return false
    }

    const disciplinasPayload = disciplinasVinculadasDraggable.filter(
      x => !x.deletado
    )

    const avaliacaoPayload = {
      ...dadosLivre.avaliacao,
      id: avaliacao?.id,
      perguntas: perguntas
    }

    const grupoDiscussaoPayload = {
      temGrupoDiscussao: temGrupoDiscussao,
      grupoDiscussaoDescricao: grupoDiscussaoDescricao
    }

    if (modo === ModoPaginaCadastro.Inclusao) {
      return await Api.InserirCursoLivre(
        dadosLivre,
        disciplinasPayload,
        avaliacaoPayload,
        grupoDiscussaoPayload
      )
    } else {
      return await Api.EditarCursoLivre(
        match.params.id,
        dadosLivre,
        disciplinasPayload,
        avaliacaoPayload,
        grupoDiscussaoPayload
      )
    }
  }

  const acaoSucessoCursoOutro = async (curso: CursoPayload) => {
    const gradePayload = gradesCurriculares.map(gradeCurricular => {
      return {
        ...gradeCurricular,
        modulos:
          gradeCurricular?.modulos?.map((x, index) => ({
            ...x,
            disciplinas: x.disciplinas?.filter(d => !d.deletado) ?? [],
            ordem: index + 1
          })) ?? [],
        moduloTCC: gradeCurricular?.moduloTCC
          ? {
              ...gradeCurricular?.moduloTCC,
              disciplinas:
                gradeCurricular?.moduloTCC?.disciplinas?.filter(
                  d => !d.deletado
                ) ?? []
            }
          : null
      }
    })

    const opcoesProrrogacaoPayload =
      prorrogacoes?.map(p => {
        return { ...p }
      }) ?? []

    const planosReingressoPayload =
      planosPagamentoReingresso?.map(p => {
        return { ...p }
      }) ?? []
    const cursoPayload = {
      ...curso,
      gradesCurriculares: gradePayload,
      opcoesProrrogacao: opcoesProrrogacaoPayload,
      planosPagamentoReingresso: planosReingressoPayload
    }

    if (modo === ModoPaginaCadastro.Inclusao) {
      return await Api.InserirCurso(cursoPayload)
    } else {
      return await Api.EditarCurso(match.params.id, cursoPayload)
    }
  }

  const acaoSucesso = async (dados: CursoPayload | CursoLivre) => {
    try {
      definirCarregando(true)

      const resultado = ehCursoLivreOuEvento
        ? await acaoSucessoCursoLivre(dados as CursoLivre)
        : await acaoSucessoCursoOutro(dados as CursoPayload)

      if (resultado) {
        toast(
          `Curso ${
            modo === ModoPaginaCadastro.Inclusao ? 'cadastrado' : 'editado'
          } com sucesso`,
          {
            type: 'success'
          }
        )

        history.push(RotasAcademico.Cursos)
      }
    } finally {
      definirCarregando(false)
    }
  }

  const carregarCurso = async () => {
    try {
      if (modo === ModoPaginaCadastro.Edicao) {
        const cursoParaEdicao = await (ehCursoLivreOuEvento
          ? Api.RequisitarCursoLivre(match.params.id)
          : Api.RequisitarCurso(match.params.id))

        const cursoLivre = cursoParaEdicao as CursoLivre
        const cursoOutro = cursoParaEdicao as CursoPayload

        definirCurso(cursoOutro)
        setParcelas(cursoOutro.planoPagamento)
        // TO-DO: Ajustar
        definirProrrogacoes([])
        definirPlanosPagamentoReingresso([])

        definirTemGrupoDiscussao(cursoLivre?.grupoDiscussao?.temGrupoDiscussao)
        definirGrupoDiscussaoDescricao(
          cursoLivre?.grupoDiscussao?.grupoDiscussaoDescricao
        )
        definirGradesCurriculares(
          cursoOutro.gradesCurriculares?.sort(comparadorDeGrades) ?? []
        )
        defineExisteGrade(!!cursoOutro?.gradesCurriculares)
        defineExibirGrade(!!cursoOutro?.gradesCurriculares)
        definirPerguntas(cursoLivre?.avaliacao?.perguntas ?? [])
        definirAvaliacao(cursoLivre?.avaliacao)

        const filtroDisciplinasVinculadas =
          cursoLivre.disciplinas ??
          cursoOutro?.gradesCurriculares
            .find(x => x.situacao === 'Ativo')
            ?.modulos?.flatMap(x => x.disciplinas) ??
          []

        definirDisciplinasVinculadasDraggable(
          filtroDisciplinasVinculadas.map(x => {
            return {
              ...x,
              draggableId: x.id
            }
          })
        )
        definirCursoPossuiTCC(cursoParaEdicao.tcc === TCCSituacao.Sim)
        definirTempoMaximoProrrogacao(cursoOutro?.tempoMaximoProrrogacao)
        definirProrrogacoes(cursoOutro?.opcoesProrrogacao ?? [])
        definirPlanosPagamentoReingresso(
          cursoOutro?.planosPagamentoReingresso ?? []
        )
      }
    } catch (error) {
      toast('Houve um problema ao obter os dados do curso', {
        type: 'error'
      })
    } finally {
      definirPronto(true)
    }
  }

  const RequisitarGrade = async (id: string) => {
    try {
      const grade = await Api.RequisitarGradePorId(id)

      const modulos = grade.modulos.map(m => ({
        ...m,
        disciplinas: m.disciplinas.map(d => ({
          id: d.id,
          nome: d.nome,
          cargaHoraria: d.cargaHoraria
        }))
      }))

      const moduloTCC =
        cursoPossuiTCC && grade.moduloTCC
          ? {
              ...grade.moduloTCC,
              disciplinas: grade.moduloTCC?.disciplinas?.map(d => ({
                id: d.id,
                nome: d.nome,
                cargaHoraria: d.cargaHoraria
              }))
            }
          : null

      const novaGrade = { ...grade, modulos, moduloTCC } as GradeCurricular

      adicionarGrade(novaGrade)
      defineExibirGrade(true)
    } catch (error) {
      toast('Houve um problema ao obter os dados da grade', {
        type: 'error'
      })
    }
  }

  const nomeAvaliacaoObrigatorio = () => {
    return !!perguntas?.length || !!quantidadeTentativas || !!descricao
  }

  useEffect(() => {
    carregarCurso()
  }, [])

  const focarNoPrimeiroErro = (idContexto?: string): void => {
    setTimeout(() => {
      const campo = document
        .querySelectorAll(
          `${
            idContexto && typeof idContexto === 'string'
              ? `#${idContexto} `
              : ''
          }.error`
        )
        .item(0) as HTMLElement

      const accordion = campo?.closest('#accordion-conteudo') as HTMLElement

      if (accordion) {
        definirErroAccordion(true)
      }

      const selecaoDoEditor = '#informacoes-container > #erro-informacoes'
      return focarPrimeiroCampoOuEditorComErro(selecaoDoEditor)(idContexto)
    }, 0)
  }

  const deveExpandirAccordion = useCallback(() => {
    if (erroAccordion) {
      const deveExpandir = erroAccordion
      definirErroAccordion(false)
      return deveExpandir
    }
  }, [erroAccordion])

  const adicionarParcela = async () => {
    if (!formaPagamentoEdicao || !quantidade || !valorFormatado) {
      toast(
        'Informe os dados: Forma de Pagamento, Valor da Parcela e Quantidade',
        {
          type: 'info'
        }
      )

      return
    }

    const novaParcela = {
      quantidadeParcelas: quantidade,
      valorParcela: valorFormatado,
      cursoId: dadosIniciais?.id,
      formaPagamento: formaPagamentoEdicao
    }

    try {
      const resposta = await Api.SalvarPlanoPagamento(novaParcela)

      if (resposta) {
        setParcelas([
          ...parcelas,
          {
            quantidade: Number(quantidade),
            valorParcela: Number(valorFormatado),
            precoBase: Number(precoBaseFormatado),
            cursoId: dadosIniciais?.id,
            formaPagamento: formaPagamentoEdicao
          }
        ])
      }
    } catch (ex) {
      return []
    }
  }

  const excluirParcela = async id => {
    setParcelas(parcelas.filter((parcela, i) => i !== id))

    await Api.EditarPlanoPagamento(
      dadosIniciais.id,
      parcelas.filter((parcela, i) => i !== id)
    )
  }

  const editarParcela = async (dado: PlanoPagamentoParcelas) => {
    for (let i = 0; i < parcelas.length; i++) {
      if (dado.id === parcelas[i].id) {
        parcelas[i] = dado
      }
    }
    await Api.EditarPlanoPagamento(dadosIniciais.id, parcelas)
  }

  const adicionarProrrogacao = (prorrogacao: PlanoPagamentoProrrogacao) => {
    if (
      !prorrogacao.quantidadeMeses ||
      !prorrogacao.valor ||
      !prorrogacao.quantidadeParcelas
    ) {
      toast(
        'Informe os dados: Tempo Prorrogação, Valor da Prorrogação e Qtd. Parcelas Prorrogação;',
        {
          type: 'info'
        }
      )

      return
    }

    if (Number(prorrogacao.quantidadeMeses) > tempoMaximoProrrogacao) {
      toast(
        'Informe os dados: Tempo Prorrogação deve ser menor ou igual ao Tempo Máximo de Prorrogação;',
        {
          type: 'info'
        }
      )

      return
    }
    const novaProrrogacao = {
      ...prorrogacao,
      id: prorrogacao?.id ?? v4()
    }

    const prorrogacaoIndex = prorrogacoes?.findIndex(
      x => x?.id === prorrogacao?.id
    )
    let prorrogacoesEditadas: PlanoPagamentoProrrogacao[] = []
    if (prorrogacaoIndex >= 0) {
      const editadas = [...prorrogacoes]
      editadas.splice(prorrogacaoIndex, 1, novaProrrogacao)
      prorrogacoesEditadas = editadas
    } else {
      prorrogacoesEditadas = [...prorrogacoes, novaProrrogacao]
    }

    prorrogacoesEditadas = prorrogacoesEditadas.map(p => {
      return {
        ...p
      }
    })

    definirProrrogacoes(prorrogacoesEditadas)
  }

  const excluirProrrogacao = async id => {
    definirProrrogacoes(prorrogacoes.filter((_, i) => i !== id))
  }

  const adicionarPlanoPagamentoReingresso = (
    plano: PlanoPagamentoReingresso
  ) => {
    if (!plano.quantidade || !plano.formaPagamento) {
      toast('Informe os dados: Quantidade, Forma de pagamento;', {
        type: 'info'
      })

      return
    }

    const novoPlano = {
      ...plano,
      id: plano?.id ?? v4()
    }

    const planoIndex = planosPagamentoReingresso?.findIndex(
      x => x?.id === plano?.id
    )

    let planosEditados: PlanoPagamentoReingresso[] = []

    if (planoIndex >= 0) {
      const editadas = [...planosPagamentoReingresso]
      editadas.splice(planoIndex, 1, novoPlano)
      planosEditados = editadas
    } else {
      planosEditados = [...planosPagamentoReingresso, novoPlano]
    }

    planosEditados = planosEditados.map(p => {
      return {
        ...p
      }
    })

    definirPlanosPagamentoReingresso(planosEditados)
  }

  const excluirPlanoPagamentoReingresso = async id => {
    definirPlanosPagamentoReingresso(
      planosPagamentoReingresso.filter((_, i) => i !== id)
    )
  }

  const setFormaPagamentoReingresso = async selecionado => {
    if (!selecionado) {
      await definirFormaPagamentoReingresso(null)
    } else {
      const { id } = selecionado
      await definirFormaPagamentoReingresso(FormaPagamentoCobranca[id])
    }
  }

  const setFormaPagamentoEdicao = async selecionado => {
    if (!selecionado) {
      await definirFormaPagamentoEdicao(null)
    } else {
      const { id } = selecionado
      await definirFormaPagamentoEdicao(FormaPagamentoCobranca[id])
    }
  }

  const deveIncluirModuloTCC = () => {
    const gradeAtiva = obterGradeAtiva()

    if (!gradeAtiva) {
      return false
    }

    return isModalidadeOutra() && cursoPossuiTCC && !gradeAtiva.moduloTCC
  }

  const verificarModuloTCC = () => {
    if (deveIncluirModuloTCC()) {
      modalModuloTCCRef?.current?.abrir([], true)
    }
  }

  const obterAreas = async (texto: string): Promise<Array<SelectOpcao>> => {
    try {
      const dados = await Api.RequisitarAreas(texto)

      return dados?.map(a => {
        return { id: a.id, texto: a.nome } as SelectOpcao
      })
    } catch {
      return []
    }
  }

  const obterAreaPorId = async (id: string): Promise<Array<SelectOpcao>> => {
    try {
      const dados = await Api.RequisitarAreaPorId(id)

      return [
        {
          id: dados.id,
          texto: dados.nome
        }
      ]
    } catch {
      return []
    }
  }

  const novoModuloDeveSerTCC = useMemo(() => {
    return deveIncluirModuloTCC()
  }, [gradesCurriculares, cursoPossuiTCC, ehCursoLivreOuEvento])

  const abrirModalAdicionarDisciplina = () =>
    modalDisciplinaRef?.current?.abrir()

  const abrirModalRemoverDisciplina = (index: number) => {
    modalDesvincularDisciplinaCursoLivreRef?.current?.abrir(index)
  }

  const removerMaterial = (index: number) => {
    definirDisciplinasVinculadasDraggable(
      disciplinasVinculadasDraggable.filter((x, i) => i !== index)
    )
  }

  useEffect(() => {
    verificarModuloTCC()
  }, [cursoPossuiTCC, gradesCurriculares])

  return (
    <>
      {pronto && (
        <Container>
          <ContainerMain>
            <ContainerLink>
              <Link
                texto="Voltar"
                href={RotasAcademico.Cursos}
                icone={IconeBack}
              />
            </ContainerLink>
            <Cabecalho>
              <Breadcrumb
                titulo={titulo}
                atalhos={[
                  {
                    texto: 'Acadêmico',
                    acao: () => history.goBack()
                  },
                  {
                    texto: 'Cursos',
                    acao: () => history.goBack()
                  },
                  {
                    texto: titulo
                  }
                ]}
              />
            </Cabecalho>
            <Conteudo>
              <FormContainer>
                <FormUnform
                  schema={criarSchema(!!perguntas?.length)}
                  dadosIniciais={dadosIniciais}
                  acaoSucesso={acaoSucesso}
                  acaoFalha={focarNoPrimeiroErro}
                >
                  <SecaoPrimaria id={Navegacoes.DadosCurso}>
                    <SubTitulo texto="Dados do Curso" />
                    <InputUnform
                      name="nome"
                      label="Nome do Curso"
                      obrigatorio
                      maxLength={200}
                    />
                    <ContainerDados>
                      <div
                        style={
                          modalidadeCurso ===
                          ModalidadeCurso.PosGraduacaoEadFlex
                            ? { marginRight: '19px', minWidth: '25%' }
                            : { marginRight: '19px', width: '50%' }
                        }
                      >
                        <InputUnform
                          disabled={PaginaEmModoEdicao}
                          name="sigla"
                          label="Sigla do Curso"
                          maxLength={20}
                          obrigatorio
                        />
                      </div>
                      <ContainerDadosMedio>
                        <SelectUnform
                          id="slct_modalidadade"
                          name="modalidade"
                          label="Modalidade do Curso"
                          disabled={modo === ModoPaginaCadastro.Edicao}
                          obrigatorio
                          valorAlterado={modalidadeAlterada}
                          margemInferior={false}
                          opcoes={[
                            {
                              id: ModalidadeCurso[ModalidadeCurso.Livre],
                              texto:
                                ModalidadeCursoPorExtenso[ModalidadeCurso.Livre]
                            },
                            {
                              id:
                                ModalidadeCurso[
                                  ModalidadeCurso.PosGraduacaoEad
                                ],
                              texto:
                                ModalidadeCursoPorExtenso[
                                  ModalidadeCurso.PosGraduacaoEad
                                ]
                            },
                            {
                              id:
                                ModalidadeCurso[
                                  ModalidadeCurso.PosGraduacaoEadFlex
                                ],
                              texto:
                                ModalidadeCursoPorExtenso[
                                  ModalidadeCurso.PosGraduacaoEadFlex
                                ]
                            },
                            {
                              id:
                                ModalidadeCurso[
                                  ModalidadeCurso.PosGraduacaoEadFull
                                ],
                              texto:
                                ModalidadeCursoPorExtenso[
                                  ModalidadeCurso.PosGraduacaoEadFull
                                ]
                            },
                            {
                              id:
                                ModalidadeCurso[
                                  ModalidadeCurso.PosGraduacaoPresencial
                                ],
                              texto:
                                ModalidadeCursoPorExtenso[
                                  ModalidadeCurso.PosGraduacaoPresencial
                                ]
                            },
                            {
                              id: ModalidadeCurso[ModalidadeCurso.Graduacao],
                              texto:
                                ModalidadeCursoPorExtenso[
                                  ModalidadeCurso.Graduacao
                                ]
                            },
                            {
                              id: ModalidadeCurso[ModalidadeCurso.Evento],
                              texto:
                                ModalidadeCursoPorExtenso[
                                  ModalidadeCurso.Evento
                                ]
                            },
                            {
                              id: ModalidadeCurso[ModalidadeCurso.Outra],
                              texto:
                                ModalidadeCursoPorExtenso[ModalidadeCurso.Outra]
                            }
                          ]}
                        />
                      </ContainerDadosMedio>
                      {modalidadeCurso ===
                        ModalidadeCurso.PosGraduacaoEadFlex && (
                        <ContainerDadosPequeno>
                          <NumeroInputUnform
                            disabled={PaginaEmModoEdicao}
                            name="quantidadeModuloFlexiveis"
                            label="Quantidade de Módulos Flex"
                            maxLength={3}
                            obrigatorio
                          />
                        </ContainerDadosPequeno>
                      )}
                    </ContainerDados>
                    {modalidadeCurso === ModalidadeCurso.Livre ? (
                      <ContainerDados>
                        <ContainerDadosMedio>
                          <SelectUnform
                            id="slct_modalidade_livre"
                            name="modalidadeLivre"
                            label="Modalidade Livre"
                            opcoes={[
                              {
                                id: ModalidadeLivre.CursoRapido,
                                texto: 'Curso Rápido'
                              },
                              {
                                id: ModalidadeLivre.Formacao,
                                texto: 'Formação'
                              },
                              {
                                id: ModalidadeLivre.Evento,
                                texto: 'Evento'
                              },
                              {
                                id: ModalidadeLivre.AoVivo,
                                texto: 'Ao vivo'
                              }
                            ]}
                          />
                        </ContainerDadosMedio>
                        <ContainerDadosMedio>
                          <SelectAsyncUnform
                            id="slct_area_curso"
                            name="idAreaCurso"
                            label="Área do Curso"
                            buscarPorId={obterAreaPorId}
                            buscarPorTexto={obterAreas}
                            placeholder="Selecione"
                          />
                        </ContainerDadosMedio>
                      </ContainerDados>
                    ) : null}
                    <ContainerDados>
                      <ContainerDadosPequeno>
                        <NumeroInputUnform
                          name="cargaHoraria"
                          label="Carga Horária (em horas)"
                          maxLength={9}
                          obrigatorio
                          isDecimal={true}
                        />
                      </ContainerDadosPequeno>
                      <ContainerDadosPequeno>
                        <NumeroInputUnform
                          id="duracao"
                          name="duracao"
                          label="Duração do Curso (em meses)"
                          maxLength={4}
                          obrigatorio
                        />
                      </ContainerDadosPequeno>
                      <ContainerDadosMedio>
                        <NumeroInputUnform
                          name="tempoDisponibilidade"
                          label="Tempo de Disponibilidade do Material (em dias)"
                          obrigatorio={ehCursoLivreOuEvento}
                        />
                      </ContainerDadosMedio>
                    </ContainerDados>
                    <ContainerDados>
                      {ehCursoLivreOuEvento && (
                        <ContainerDadosPequeno>
                          <CalendarioUnform
                            className="sm"
                            id="ipt_data_inicio"
                            name="dataInicio"
                            label="Data de Início do Curso"
                            obrigatorio
                          />
                        </ContainerDadosPequeno>
                      )}
                      <ContainerDadosPequeno>
                        <SeletorUnform
                          name="situacao"
                          tipo="radio"
                          label="Situação"
                          seletores={[
                            {
                              id: CursoSituacao[CursoSituacao.Ativo],
                              texto: 'Ativo'
                            },
                            {
                              id: CursoSituacao[CursoSituacao.Inativo],
                              texto: 'Inativo'
                            }
                          ]}
                        />
                      </ContainerDadosPequeno>
                      {ehCursoLivreOuEvento && (
                        <Opcao>
                          <CampoLabel>Possui Fórum de dúvidas?</CampoLabel>
                          <OpcaoDados>
                            <CheckboxUnform
                              id="chk_possui_grupo_discussao"
                              name="temGrupoDiscussao"
                              onChange={() =>
                                definirTemGrupoDiscussao(old => !old)
                              }
                              checked={temGrupoDiscussao}
                            />
                            <p>Sim</p>
                          </OpcaoDados>
                        </Opcao>
                      )}
                      {isModalidadeOutra() ? (
                        <>
                          <ContainerDadosPequeno>
                            <SeletorUnform
                              name="tcc"
                              tipo="radio"
                              label="Curso possui TCC?"
                              seletores={[
                                {
                                  id: TCCSituacao.Sim,
                                  texto: 'Sim'
                                },
                                {
                                  id: TCCSituacao.Nao,
                                  texto: 'Não'
                                }
                              ]}
                              valorAlterado={opcao => {
                                const selecionado = opcao as TCCSituacao
                                definirCursoPossuiTCC(
                                  selecionado === TCCSituacao.Sim
                                )
                              }}
                              disabled={modo === ModoPaginaCadastro.Edicao}
                            />
                          </ContainerDadosPequeno>
                          <ContainerDadosMedio>
                            <SelectUnform
                              id="slct_avaliacao"
                              name="tipoAvaliacao"
                              label="Tipo de Avaliação"
                              placeholder="Selecione"
                              obrigatorio
                              opcoes={[
                                {
                                  id:
                                    TipoAvaliacao[
                                      TipoAvaliacao.AvaliacaoPorDisciplina
                                    ],
                                  texto: 'Avaliação por Disciplina'
                                },
                                {
                                  id:
                                    TipoAvaliacao[
                                      TipoAvaliacao.AvaliacaoPorModulo
                                    ],
                                  texto: 'Avaliação por Módulo'
                                }
                              ]}
                            />
                          </ContainerDadosMedio>
                        </>
                      ) : null}
                    </ContainerDados>
                    {ehCursoLivreOuEvento && (
                      <>
                        <ContainerDados>
                          <CheckboxUnform
                            id="chk-sincronizar-rubeus"
                            name="sincronizarCrm"
                            tamanho="L"
                            texto={'Sincronizar RD'}
                          />
                        </ContainerDados>
                        <Spacer padding="15px 0px" />
                      </>
                    )}
                  </SecaoPrimaria>
                  {temGrupoDiscussao && (
                    <>
                      <SubTitulo texto="Fórum de dúvidas" />
                      <Scope path="grupoDiscussao">
                        <TextAreaUnform
                          value={grupoDiscussaoDescricao}
                          onChange={e =>
                            definirGrupoDiscussaoDescricao(e.target.value)
                          }
                          name="grupoDiscussaoDescricao"
                          label="Mensagem"
                        />
                      </Scope>
                    </>
                  )}
                  <SubTitulo texto="Informações Financeiras" />
                  <Secao id={Navegacoes.InformacoesFinanceiras}>
                    {cursoPossuiTCC && isModalidadeOutra() && (
                      <Accordion
                        titulo="Valores TCC"
                        key={'acc_valores_tcc'}
                        classe={'accordion_valores'}
                        desativarScrollExpandir
                        deveExpandir={deveExpandirAccordion}
                      >
                        <ContainerDados>
                          <ContainerDadosMedio>
                            <MoedaInputUnform
                              name="precoBaseTCC"
                              label="Valor da Taxa do TCC"
                              obrigatorio
                            />
                          </ContainerDadosMedio>
                          <ContainerDadosMedio>
                            <NumeroInputUnform
                              name="quantidadeMaximaParcelasTCC"
                              max={9999}
                              maxLength={4}
                              label="Quantidade Máxima de Parcelas do TCC"
                              obrigatorio
                            />
                          </ContainerDadosMedio>
                        </ContainerDados>
                      </Accordion>
                    )}
                    <Accordion
                      titulo="Valores Matrícula"
                      key={'acc_valores_matricula'}
                      classe={'accordion_valores'}
                      desativarScrollExpandir
                      deveExpandir={deveExpandirAccordion}
                    >
                      <ContainerDados>
                        <ContainerDadosMedio>
                          <MoedaInputUnform
                            name="valorTaxaMatricula"
                            label="Valor da Taxa de Matrícula"
                            obrigatorio
                          />
                        </ContainerDadosMedio>
                        <ContainerDadosMedio>
                          <NumeroInputUnform
                            name="quantidadeMaximaParcelas"
                            max={9999}
                            maxLength={4}
                            label="Quantidade Máxima de Parcelas no Cartão de Crédito"
                            obrigatorio
                          />
                        </ContainerDadosMedio>
                      </ContainerDados>
                    </Accordion>
                    {isModalidadeOutra() && (
                      <Accordion
                        titulo="Valores Curso"
                        key={'acc_valores_curso'}
                        classe={'accordion_valores'}
                        desativarScrollExpandir
                        deveExpandir={deveExpandirAccordion}
                      >
                        {modo === ModoPaginaCadastro.Inclusao && (
                          <ContainerDados>
                            <ContainerDadosMedio>
                              <MoedaInputUnform
                                name="taxaTrocaDeCurso"
                                label="Taxa Troca de Curso"
                              />
                            </ContainerDadosMedio>
                          </ContainerDados>
                        )}
                        {modo === ModoPaginaCadastro.Edicao && (
                          <>
                            <ContainerDados>
                              <ContainerDadosMedio>
                                <MoedaInputUnform
                                  name="precoBase"
                                  label="Preço base"
                                  onChange={e => setPrecoBase(e.target.value)}
                                  obrigatorio
                                />
                              </ContainerDadosMedio>
                              <ContainerDadosMedio>
                                <MoedaInputUnform
                                  name="taxaTrocaDeCurso"
                                  label="Taxa Troca de Curso"
                                />
                              </ContainerDadosMedio>
                            </ContainerDados>
                            <ContainerDados style={{ marginTop: '27px' }}>
                              <ContainerDadosMedio>
                                <SelectUnform
                                  id="slct_formPagamentoParcela"
                                  name="formPagamentoParcela"
                                  label="Forma de Pagamento"
                                  placeholder="Selecione"
                                  valorAlterado={setFormaPagamentoEdicao}
                                  opcoes={[
                                    {
                                      id:
                                        FormaPagamentoCobranca[
                                          FormaPagamentoCobranca.Boleto
                                        ],
                                      texto: 'Boleto'
                                    },
                                    {
                                      id:
                                        FormaPagamentoCobranca[
                                          FormaPagamentoCobranca.CartaoCredito
                                        ],
                                      texto: 'Cartão de crédito'
                                    }
                                  ]}
                                />
                              </ContainerDadosMedio>
                              <ContainerDadosPequeno>
                                <MoedaInputUnform
                                  name="valorParcela"
                                  label="Valor da parcela"
                                  onChange={e =>
                                    setValorParcela(e.target.value)
                                  }
                                />
                              </ContainerDadosPequeno>
                              <ContainerDadosPequeno>
                                <NumeroInputUnform
                                  name="quantidade"
                                  max={9999}
                                  maxLength={4}
                                  label="Quantidade de parcelas"
                                  onChange={e =>
                                    setQuantidadeParcelas(e.target.value)
                                  }
                                />
                                <Botao
                                  texto="Adicionar parcelamento"
                                  tema="Secundario"
                                  type="button"
                                  style={{ margin: '0 0 0 auto' }}
                                  onClick={() => adicionarParcela()}
                                />
                              </ContainerDadosPequeno>
                            </ContainerDados>
                            <Tabela>
                              <thead>
                                <tr>
                                  <th>Quantidade de parcelas</th>
                                  <th>Valor</th>
                                  <th>Forma de Pagamento</th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {parcelas?.map((parcela, index) => (
                                  <tr key={index}>
                                    <td align="center">{parcela.quantidade}</td>
                                    <td align="center">
                                      R$ {parcela.valorParcela}
                                    </td>
                                    <td align="center">
                                      {
                                        FormaPagamentoPorExtenso[
                                          parcela.formaPagamento
                                        ]
                                      }{' '}
                                    </td>
                                    <td align="center">
                                      <ContainerBotaoIcone>
                                        <button
                                          type="button"
                                          data-tip="Editar parcela"
                                          data-for={'tooltip-editar-parcela'}
                                          onClick={() => {
                                            modalIncluirParcelaRef?.current?.abrir(
                                              parcelas[index]
                                            )
                                          }}
                                        >
                                          {IconeEditar}
                                        </button>
                                        <Tooltip
                                          id={'tooltip-editar-parcela'}
                                          place="bottom"
                                          offset={{ top: 10 }}
                                        />
                                      </ContainerBotaoIcone>
                                    </td>
                                    <td align="center">
                                      <ContainerBotaoIcone>
                                        <button
                                          type="button"
                                          data-tip="Excluir parcela"
                                          data-for={'tooltip-lixeira-parcela'}
                                          onClick={() => excluirParcela(index)}
                                        >
                                          {IconeLixeira}
                                        </button>
                                        <Tooltip
                                          id={'tooltip-lixeira-parcela'}
                                          place="bottom"
                                          offset={{ top: 10 }}
                                        />
                                      </ContainerBotaoIcone>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Tabela>
                          </>
                        )}
                      </Accordion>
                    )}
                    {ehPosGraduacao && (
                      <Accordion
                        titulo="Valores Prorrogação"
                        key={'acc_valores_prorrogacao'}
                        classe={'accordion_valores'}
                        desativarScrollExpandir
                        deveExpandir={deveExpandirAccordion}
                      >
                        <ContainerDados>
                          <ContainerDadosPequeno>
                            <NumeroInputUnform
                              name="tempoMaximoProrrogacao"
                              max={9999}
                              maxLength={4}
                              label="Tempo Máximo de Prorrogação (em meses)"
                              onChange={e =>
                                definirTempoMaximoProrrogacao(
                                  Number(e.target.value)
                                )
                              }
                            />
                          </ContainerDadosPequeno>
                        </ContainerDados>
                        {tempoMaximoProrrogacao > 0 && (
                          <>
                            <ContainerDados style={{ marginTop: '27px' }}>
                              <ContainerDadosMedio>
                                <NumeroInputUnform
                                  name="tempoProrrogacao"
                                  max={9999}
                                  maxLength={4}
                                  label="Tempo de Prorrogação (em meses)"
                                  onChange={e =>
                                    definirTempoProrrogacao(e.target.value)
                                  }
                                />
                              </ContainerDadosMedio>
                              <ContainerDadosPequeno>
                                <MoedaInputUnform
                                  name="valorProrrogacao"
                                  label="Valor da Prorrogação"
                                  onChange={e =>
                                    definirValorProrrogacao(e.target.value)
                                  }
                                />
                              </ContainerDadosPequeno>
                              <ContainerDadosPequeno>
                                <NumeroInputUnform
                                  name="quantidadeParcelasProrrogacao"
                                  max={9999}
                                  maxLength={4}
                                  label="Quantidade de parcelas"
                                  onChange={e =>
                                    definirQtdParcelasProrrogacao(
                                      e.target.value
                                    )
                                  }
                                />
                                <Botao
                                  texto="Adicionar prorrogação"
                                  tema="Secundario"
                                  type="button"
                                  style={{ margin: '0 0 0 auto' }}
                                  onClick={() =>
                                    adicionarProrrogacao({
                                      quantidadeMeses: tempoProrrogacao,
                                      valor: valorProrrogacaoFormatado,
                                      quantidadeParcelas: qtdParcelasProrrogacao
                                    } as PlanoPagamentoProrrogacao)
                                  }
                                />
                              </ContainerDadosPequeno>
                            </ContainerDados>
                            <Tabela>
                              <thead>
                                <tr>
                                  <th>Tempo Prorrogação</th>
                                  <th>Valor</th>
                                  <th>Quantidade de Parcelas</th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {prorrogacoes?.map((prorrogacao, index) => (
                                  <tr key={index}>
                                    <td align="center">
                                      {prorrogacao.quantidadeMeses}
                                    </td>
                                    <td align="center">
                                      R$ {prorrogacao.valor}
                                    </td>
                                    <td align="center">
                                      {prorrogacao.quantidadeParcelas}
                                    </td>
                                    <td align="center">
                                      <ContainerBotaoIcone>
                                        <button
                                          type="button"
                                          data-tip="Editar prorrogação"
                                          data-for={
                                            'tooltip-editar-prorrogacao'
                                          }
                                          onClick={() => {
                                            modalIncluirProrrogacaoRef?.current?.abrir(
                                              prorrogacoes[index]
                                            )
                                          }}
                                        >
                                          {IconeEditar}
                                        </button>
                                        <Tooltip
                                          id={'tooltip-editar-prorrogacao'}
                                          place="bottom"
                                          offset={{ top: 10 }}
                                        />
                                      </ContainerBotaoIcone>
                                    </td>
                                    <td align="center">
                                      <ContainerBotaoIcone>
                                        <button
                                          type="button"
                                          data-tip="Excluir prorrogação"
                                          data-for={
                                            'tooltip-lixeira-prorrogacao'
                                          }
                                          onClick={() =>
                                            excluirProrrogacao(index)
                                          }
                                        >
                                          {IconeLixeira}
                                        </button>
                                        <Tooltip
                                          id={'tooltip-lixeira-prorrogacao'}
                                          place="bottom"
                                          offset={{ top: 10 }}
                                        />
                                      </ContainerBotaoIcone>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Tabela>
                          </>
                        )}
                      </Accordion>
                    )}
                    {isModalidadePosGraduacao && (
                      <Accordion
                        titulo="Valores Recuperação"
                        key={'acc_valores_recuperacao'}
                        classe={'accordion_valores'}
                        desativarScrollExpandir
                        deveExpandir={deveExpandirAccordion}
                      >
                        <ContainerDados className="campos-recuperacao">
                          <ContainerDadosPequeno>
                            <MoedaInputUnform
                              id="valorRecuperacao"
                              name="valorRecuperacao"
                              label={
                                <>
                                  <p>
                                    <ContainerIcone
                                      data-tip="Uma reprovação consiste em um módulo ou cada 20% das disciplinas"
                                      data-for={'tooltip-valor-recuperacao'}
                                    >
                                      {IconeInformacao}
                                    </ContainerIcone>
                                    Valor da Recuperação por Reprovação
                                  </p>
                                  <Tooltip
                                    id={'tooltip-valor-recuperacao'}
                                    place="right"
                                    offset={{ top: 10 }}
                                  />
                                </>
                              }
                              maxLength={9}
                              obrigatorio
                              isDecimal={true}
                            />
                          </ContainerDadosPequeno>
                          <ContainerDadosPequeno>
                            <NumeroInputUnform
                              id="tempoRecuperacao"
                              name="tempoRecuperacao"
                              label={
                                <>
                                  <p>
                                    <ContainerIcone
                                      data-tip="Uma reprovação consiste em um módulo ou cada 20% das disciplinas"
                                      data-for={'tooltip-tempo-recuperacao'}
                                    >
                                      {IconeInformacao}
                                    </ContainerIcone>
                                    Tempo para Recuperação por Reprovação (em
                                    meses)
                                  </p>
                                  <Tooltip
                                    id={'tooltip-tempo-recuperacao'}
                                    place="right"
                                    offset={{ top: 10 }}
                                  />
                                </>
                              }
                              maxLength={4}
                              obrigatorio
                            />
                          </ContainerDadosPequeno>
                          <ContainerDadosMedio>
                            <NumeroInputUnform
                              name="quantidadeMaximaParcelasRecuperacao"
                              label="Quantidade máxima de parcelas para Cartão de Crédito"
                              obrigatorio
                            />
                          </ContainerDadosMedio>
                        </ContainerDados>
                      </Accordion>
                    )}
                    {isModalidadePosGraduacao && (
                      <Accordion
                        titulo="Valores Reingresso"
                        key={'acc_valores_reingresso'}
                        classe={'accordion_valores'}
                        desativarScrollExpandir
                        deveExpandir={deveExpandirAccordion}
                      >
                        <ContainerDados>
                          <ContainerDadosPequeno>
                            <MoedaInputUnform
                              name="precoBaseReingresso"
                              label="Preço Base Reingresso"
                              obrigatorio
                            />
                          </ContainerDadosPequeno>
                          <ContainerDadosPequeno>
                            <MoedaInputUnform
                              name="valorTaxaReingresso"
                              label="Taxa Reingresso"
                              obrigatorio
                            />
                          </ContainerDadosPequeno>
                          <ContainerDadosPequeno>
                            <NumeroInputUnform
                              name="quantidadeMaximaParcelasReingresso"
                              max={999}
                              maxLength={3}
                              label="Quantidade Máxima de Parcelas no Cartão de Crédito"
                              obrigatorio
                            />
                          </ContainerDadosPequeno>
                        </ContainerDados>
                        <ContainerDados style={{ marginTop: '27px' }}>
                          <ContainerDadosMedio>
                            <SelectUnform
                              id="slct_formPagamentoParcela"
                              name="formPagamentoParcelaReingresso"
                              label="Forma de Pagamento"
                              placeholder="Selecione"
                              valorAlterado={setFormaPagamentoReingresso}
                              opcoes={[
                                {
                                  id:
                                    FormaPagamentoCobranca[
                                      FormaPagamentoCobranca.Boleto
                                    ],
                                  texto: 'Boleto'
                                },
                                {
                                  id:
                                    FormaPagamentoCobranca[
                                      FormaPagamentoCobranca.CartaoCredito
                                    ],
                                  texto: 'Cartão de crédito'
                                }
                              ]}
                            />
                          </ContainerDadosMedio>
                          <ContainerDadosMedio>
                            <NumeroInputUnform
                              name="quantidadeReingresso"
                              max={9999}
                              maxLength={4}
                              label="Quantidade de parcelas"
                              onChange={e =>
                                definirQuantidadeParcelasReingresso(
                                  e.target.value
                                )
                              }
                            />
                            <Botao
                              texto="Adicionar parcelamento"
                              tema="Secundario"
                              type="button"
                              style={{ margin: '0 0 0 auto' }}
                              onClick={() =>
                                adicionarPlanoPagamentoReingresso({
                                  quantidade: quantidadeParcelasReingresso,
                                  formaPagamento: formaPagamentoReingresso
                                } as PlanoPagamentoReingresso)
                              }
                            />
                          </ContainerDadosMedio>
                        </ContainerDados>
                        <Tabela>
                          <thead>
                            <tr>
                              <th>Quantidade de parcelas</th>
                              <th>Forma de Pagamento</th>
                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {planosPagamentoReingresso?.map(
                              (parcela, index) => (
                                <tr key={index}>
                                  <td align="center">{parcela.quantidade}</td>
                                  <td align="center">
                                    {
                                      FormaPagamentoPorExtenso[
                                        parcela.formaPagamento
                                      ]
                                    }
                                  </td>
                                  <td align="center">
                                    <ContainerBotaoIcone>
                                      <button
                                        type="button"
                                        data-tip="Editar parcela"
                                        data-for={'tooltip-editar-parcela'}
                                        onClick={() => {
                                          modalIncluirPlanoReingresso?.current?.abrir(
                                            planosPagamentoReingresso[index]
                                          )
                                        }}
                                      >
                                        {IconeEditar}
                                      </button>
                                      <Tooltip
                                        id={'tooltip-editar-parcela'}
                                        place="bottom"
                                        offset={{ top: 10 }}
                                      />
                                    </ContainerBotaoIcone>
                                  </td>
                                  <td align="center">
                                    <ContainerBotaoIcone>
                                      <button
                                        type="button"
                                        data-tip="Excluir parcela"
                                        data-for={'tooltip-lixeira-parcela'}
                                        onClick={() =>
                                          excluirPlanoPagamentoReingresso(index)
                                        }
                                      >
                                        {IconeLixeira}
                                      </button>
                                      <Tooltip
                                        id={'tooltip-lixeira-parcela'}
                                        place="bottom"
                                        offset={{ top: 10 }}
                                      />
                                    </ContainerBotaoIcone>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Tabela>
                      </Accordion>
                    )}
                    {isModalidadeOutra() && (
                      <ContainerDadosMedio>
                        <ArquivoInputUnform
                          label="Contrato"
                          name="contrato"
                          obrigatorio
                          tiposAceitos={[
                            '.pdf',
                            '.doc',
                            '.docx',
                            '.xls',
                            '.xlsx',
                            '.csv',
                            '.html',
                            '.htm'
                          ]}
                        />
                      </ContainerDadosMedio>
                    )}
                  </Secao>
                  {isModalidadeOutra() ? (
                    <Secao id={Navegacoes.GradeCurricular}>
                      <SubTitulo texto="Grade Curricular" />
                      <ConteudoGrade>
                        <BotaoIcone
                          id="btn_adicionar_grade"
                          type="button"
                          onClick={() => {
                            modalIncluirGradeRef?.current?.abrir(
                              obterGradeAtiva()?.nome
                            )
                          }}
                        >
                          {IconeAdicionar} Incluir Grade
                        </BotaoIcone>
                        <ContainerBotao>
                          <BotaoIcone
                            id="btn_adicionar_grade"
                            type="button"
                            onClick={() => {
                              modalCopiarGradeRef?.current?.abrir(
                                obterGradeAtiva()?.nome
                              )
                            }}
                          >
                            {IconeCopiar} Copiar de Outro Curso
                          </BotaoIcone>
                        </ContainerBotao>
                      </ConteudoGrade>
                      {exibirGrade && gradesCurriculares
                        ? gradesCurriculares.map((gradeCurricular, key) => (
                            <ContainerGrade key={key}>
                              <Accordion
                                titulo={`${gradeCurricular.situacao} - ${gradeCurricular.nome}`}
                                inativo={gradeCurricular.situacao !== 'Ativo'}
                                iniciarAberto={
                                  gradeCurricular.situacao === 'Ativo'
                                }
                              >
                                {!!gradeCurricular.moduloTCC && (
                                  <>
                                    <ListaModulos>
                                      {`TCC - ${gradeCurricular.moduloTCC.nome}`}
                                      <ContainerBotao>
                                        {gradeCurricular.situacao ===
                                          'Ativo' && (
                                          <Botao
                                            type="button"
                                            tema="Link"
                                            elemento={
                                              <ConteudoBotao>
                                                {IconeAdicionar} Vincular
                                                Disciplina
                                              </ConteudoBotao>
                                            }
                                            onClick={() => {
                                              modalDisciplinaTCCRef?.current?.abrir()
                                            }}
                                          />
                                        )}
                                      </ContainerBotao>
                                    </ListaModulos>
                                    {gradeCurricular.moduloTCC.disciplinas?.some(
                                      x => !x.deletado
                                    ) ? (
                                      <ContainerDisciplinas>
                                        <Tabela>
                                          <thead>
                                            <th align="center">Disciplina</th>
                                            <th align="center">
                                              Carga Horária
                                            </th>
                                            {gradeCurricular.situacao ===
                                              'Ativo' && (
                                              <th align="center"></th>
                                            )}
                                          </thead>
                                          <tbody>
                                            {gradeCurricular.moduloTCC.disciplinas.map(
                                              disciplina =>
                                                !disciplina.deletado && (
                                                  <tr
                                                    key={`vincular-tcc-${disciplina.id}`}
                                                  >
                                                    <td align="center">
                                                      {disciplina.nome}
                                                    </td>
                                                    <td align="center">
                                                      {disciplina.cargaHoraria}
                                                    </td>
                                                    {gradeCurricular.situacao ===
                                                      'Ativo' && (
                                                      <td>
                                                        <ContainerBotaoIcone>
                                                          <button
                                                            type="button"
                                                            data-tip="Desvincular"
                                                            data-for={
                                                              'tooltip-lixeira'
                                                            }
                                                            onClick={() => {
                                                              abrirModalExcluirDisciplinaTCC(
                                                                disciplina as Disciplina
                                                              )
                                                            }}
                                                          >
                                                            {IconeLixeira}
                                                          </button>
                                                          <Tooltip
                                                            id={
                                                              'tooltip-lixeira'
                                                            }
                                                            place="bottom"
                                                            offset={{ top: 10 }}
                                                          />
                                                        </ContainerBotaoIcone>
                                                      </td>
                                                    )}
                                                  </tr>
                                                )
                                            )}
                                          </tbody>
                                        </Tabela>
                                      </ContainerDisciplinas>
                                    ) : null}
                                    <Spacer padding="5px 5px" />
                                  </>
                                )}
                                {gradeCurricular.modulos?.map((item, i) => (
                                  <>
                                    <ListaModulos>
                                      <TituloModulo>
                                        {`${i + 1}. ${item.nome}`}
                                        {item.tipoModulo === 'Flex' && (
                                          <SeloFlex>FLEX</SeloFlex>
                                        )}
                                      </TituloModulo>
                                      {gradeCurricular?.modulos?.length >= 1 ? (
                                        <ContainerBotao>
                                          {gradeCurricular.situacao ===
                                            'Ativo' && (
                                            <div style={{ display: 'flex' }}>
                                              {modalidadeCurso ===
                                                ModalidadeCurso.PosGraduacaoEadFlex && (
                                                <Botao
                                                  type="button"
                                                  tema="Link"
                                                  elemento={
                                                    <ConteudoBotaoEditarModulo>
                                                      {IconeDeEdicaoDeUpload}
                                                      Editar Módulo
                                                    </ConteudoBotaoEditarModulo>
                                                  }
                                                  onClick={() => {
                                                    modalEditarModuloRef?.current?.abrir(
                                                      item
                                                    )
                                                  }}
                                                />
                                              )}

                                              <Botao
                                                type="button"
                                                tema="Link"
                                                elemento={
                                                  <ConteudoBotao>
                                                    {IconeAdicionar} Vincular
                                                    Disciplina
                                                  </ConteudoBotao>
                                                }
                                                onClick={() => {
                                                  modalDisciplinaRef?.current?.abrir()
                                                  defineIndex(i)
                                                }}
                                              />
                                            </div>
                                          )}
                                        </ContainerBotao>
                                      ) : null}
                                    </ListaModulos>
                                    {item.disciplinas?.some(
                                      d => !d.deletado
                                    ) ? (
                                      <ContainerDisciplinas>
                                        <Tabela>
                                          <thead>
                                            <th align="center">Disciplina</th>
                                            <th align="center">
                                              Carga Horária
                                            </th>
                                            {gradeCurricular.situacao ===
                                              'Ativo' && (
                                              <th align="center"></th>
                                            )}
                                          </thead>
                                          <tbody>
                                            {item.disciplinas.map(
                                              disciplina =>
                                                !disciplina.deletado && (
                                                  <tr
                                                    key={`vincular-${disciplina.id}`}
                                                  >
                                                    <td align="center">
                                                      {disciplina.nome}
                                                    </td>
                                                    <td align="center">
                                                      {disciplina.cargaHoraria}
                                                    </td>
                                                    {gradeCurricular.situacao ===
                                                      'Ativo' && (
                                                      <td>
                                                        <ContainerBotaoIcone>
                                                          <button
                                                            type="button"
                                                            data-tip="Desvincular"
                                                            data-for={
                                                              'tooltip-lixeira'
                                                            }
                                                            onClick={() => {
                                                              defineIndex(i)
                                                              abrirModalExcluirDisciplina(
                                                                disciplina as Disciplina,
                                                                i
                                                              )
                                                            }}
                                                          >
                                                            {IconeLixeira}
                                                          </button>
                                                          <Tooltip
                                                            id={
                                                              'tooltip-lixeira'
                                                            }
                                                            place="bottom"
                                                            offset={{ top: 10 }}
                                                          />
                                                        </ContainerBotaoIcone>
                                                      </td>
                                                    )}
                                                  </tr>
                                                )
                                            )}
                                          </tbody>
                                        </Tabela>
                                      </ContainerDisciplinas>
                                    ) : null}
                                    <Spacer padding="5px 5px" />
                                  </>
                                ))}
                                <ContainerBotao>
                                  {gradeCurricular.situacao === 'Ativo' && (
                                    <BotaoIcone
                                      id="btn_adicionar_modulo"
                                      type="button"
                                      onClick={() => {
                                        if (novoModuloDeveSerTCC) {
                                          modalModuloTCCRef?.current?.abrir(
                                            obterGradeAtiva()?.modulos?.map(
                                              x => x.nome
                                            ) ?? [],
                                            true
                                          )
                                        } else {
                                          modalModuloRef?.current?.abrir(
                                            obterGradeAtiva()?.modulos?.map(
                                              x => x.nome
                                            ) ?? [],
                                            false,
                                            modalidadeCurso ===
                                              ModalidadeCurso.PosGraduacaoEadFlex
                                          )
                                        }
                                      }}
                                    >
                                      {IconeAdicionar}{' '}
                                      {novoModuloDeveSerTCC
                                        ? 'Incluir Módulo TCC'
                                        : 'Incluir Módulo'}
                                    </BotaoIcone>
                                  )}
                                </ContainerBotao>
                              </Accordion>
                            </ContainerGrade>
                          ))
                        : null}
                    </Secao>
                  ) : null}
                  {ehCursoLivre ? (
                    <Secao id={Navegacoes.InformacoesIniciasCurso}>
                      <SubTitulo texto="Informações de Apresentação do Curso" />
                      <InputUnform
                        name="informacoesIniciais"
                        label="Link do vídeo de apresentação do curso"
                        maxLength={200}
                      />
                    </Secao>
                  ) : null}
                  <Secao id={Navegacoes.InformacoesCurso}>
                    <SubTitulo texto="Informações do Curso" />
                    <EditorUnform
                      id="informacoes"
                      name="informacoes"
                      label={
                        <>
                          <ContainerIcone>{IconeInformacao}</ContainerIcone>
                          {
                            'Essas informações serão exibidas na sala de aula virtual.'
                          }
                        </>
                      }
                      obrigatorio
                    />
                  </Secao>
                  {ehCursoLivreOuEvento ? (
                    <>
                      <Secao id={Navegacoes.Disciplinas}>
                        <ListaDisciplina
                          adicionar={abrirModalAdicionarDisciplina}
                          setMateriais={item =>
                            definirDisciplinasVinculadasDraggable(item)
                          }
                          materiais={
                            disciplinasVinculadasDraggable as DisciplinaDraggable[]
                          }
                          remover={abrirModalRemoverDisciplina}
                        />
                      </Secao>
                      <Secao id={Navegacoes.Avaliacoes}>
                        <SubTitulo texto="Avaliação" />
                        <Linha>
                          <CheckboxUnform
                            id={'ipt_possui_avalicao'}
                            name="possuiAvaliacao"
                            texto="Tem avaliação?"
                            onChange={() => definirPossuiAvaliacao(old => !old)}
                            checked={possuiAvaliacao || curso?.possuiAvaliacao}
                            disabled={curso?.possuiAvaliacao}
                          />

                          {(possuiAvaliacao && !curso?.avaliacao?.id) ||
                          (!possuiAvaliacao &&
                            curso?.possuiAvaliacao &&
                            !curso?.avaliacao?.id) ? (
                            <CheckboxUnform
                              id={'ipt_incluir_avalicao'}
                              name="adicionarAvaliacao"
                              texto="Adicionar avaliação"
                              onChange={() =>
                                definirAdicionarAvaliacao(old => !old)
                              }
                              checked={adicionarAvaliacao}
                            />
                          ) : null}
                        </Linha>
                        {(possuiAvaliacao && adicionarAvaliacao) ||
                        (curso?.possuiAvaliacao && adicionarAvaliacao) ||
                        curso?.avaliacao?.id ? (
                          <>
                            <Spacer padding="10px 0px" />
                            <Scope path="avaliacao">
                              <ContainerDados>
                                <ContainerDadosMedio>
                                  <InputUnform
                                    name="nome"
                                    label="Nome da Avaliação"
                                    obrigatorio={nomeAvaliacaoObrigatorio()}
                                  />
                                </ContainerDadosMedio>
                                <ContainerDadosPequeno>
                                  <NumeroInputUnform
                                    value={quantidadeTentativas}
                                    onChange={e =>
                                      definirQuantidadeTentativas(
                                        e.target.value
                                      )
                                    }
                                    name="quantidadeTentativas"
                                    label="Quantidade de Tentativas"
                                    max={9999}
                                  />
                                </ContainerDadosPequeno>
                              </ContainerDados>
                              <TextAreaUnform
                                value={descricao}
                                onChange={e => definirDescricao(e.target.value)}
                                name="descricao"
                                label="Descrição da Avaliação"
                              />
                            </Scope>
                            <ListaPerguntas
                              items={perguntas ?? []}
                              adicionar={abrirModalAdicionarPergunta}
                              remover={removerPergunta}
                            />
                            <Spacer padding="30px 0px 0px" />
                          </>
                        ) : null}
                      </Secao>
                      <Spacer padding="30px 0px 0px" />
                      <Secao id={Navegacoes.PesquisaSatisfacao}>
                        <SubTitulo texto="Pesquisa de Satisfação" />
                        <SelectAsyncUnform
                          className="lg"
                          id="slct_pesquisa_satisfacao"
                          name="pesquisaSatisfacaoId"
                          label="Pesquisa de Satisfação"
                          placeholder="Selecione"
                          buscarPorTexto={carregarPesquisaSatisfacaoTexto}
                          buscarPorId={carregarPesquisaSatisfacaoPorId}
                        />
                      </Secao>
                      <Secao id={Navegacoes.VersoCertificado}>
                        <SubTitulo texto="Verso do Certificado" />
                        <EditorUnform
                          id="certificado"
                          name="versoCertificado"
                          label={
                            <>
                              <ContainerIcone>{IconeInformacao}</ContainerIcone>
                              Este texto será impresso no verso do certificado
                              de conclusão do curso.
                            </>
                          }
                        />
                      </Secao>
                    </>
                  ) : null}
                  <Spacer padding="10px 0px" />
                  <ContainerAcoes>
                    <Botao
                      texto="Cancelar"
                      tema="Secundario"
                      type="button"
                      disabled={carregando}
                      onClick={() => modalRef.current?.abrir()}
                    />
                    <Botao
                      type="submit"
                      texto="Salvar"
                      carregando={carregando}
                    />
                  </ContainerAcoes>
                </FormUnform>
              </FormContainer>
            </Conteudo>
          </ContainerMain>
          <ContainerNavegacao>
            <div>
              <Navegacao
                itens={[
                  {
                    link: Navegacoes.DadosCurso,
                    nome: 'Dados do Curso'
                  },
                  {
                    link: Navegacoes.InformacoesFinanceiras,
                    nome: 'Informações Financeiras'
                  },
                  ehCursoLivre && {
                    link: Navegacoes.InformacoesIniciasCurso,
                    nome: 'Informações Iniciais do Curso'
                  },
                  {
                    link: Navegacoes.InformacoesCurso,
                    nome: 'Informações do Curso'
                  },
                  ehCursoLivreOuEvento && {
                    link: Navegacoes.Disciplinas,
                    nome: 'Disciplinas'
                  },
                  ehCursoLivreOuEvento && {
                    link: Navegacoes.Avaliacoes,
                    nome: 'Avaliação'
                  },
                  ehCursoLivreOuEvento && {
                    link: Navegacoes.PesquisaSatisfacao,
                    nome: 'Pesquisa de Satisfação'
                  },
                  ehCursoLivreOuEvento && {
                    link: Navegacoes.VersoCertificado,
                    nome: 'Verso do Certificado'
                  },
                  isModalidadeOutra() && {
                    link: Navegacoes.GradeCurricular,
                    nome: 'Grade Curricular'
                  }
                ]}
              />
            </div>
          </ContainerNavegacao>
          <Modal
            ref={modalRef}
            backdrop
            id="modal-confirmacao-cancelamento"
            icone={<></>}
            titulo={'Deseja cancelar?'}
            acaoPrimaria={{
              titulo: 'Sim',
              tipo: 'button',
              acao: () => history.goBack()
            }}
            acaoSecundario={{
              titulo: 'Não',
              tipo: 'button',
              acao: () => modalRef.current?.fechar()
            }}
          >
            <p>Selecione uma opção</p>
          </Modal>
          {modalidadeCurso && (
            <>
              <ModalVincular
                id="modal-vincular-curso"
                ref={modalDisciplinaRef}
                acaoPrimaria={dados => {
                  adicionaDisciplinaClick(dados)
                  modalDisciplinaRef?.current?.fechar()
                }}
                backdrop
                modalidade={
                  ehCursoLivreOuEvento
                    ? modalidadeCurso === 'Livre'
                      ? ModalidadeCurso.Livre
                      : ModalidadeCurso.Evento
                    : ModalidadeCurso.Outra
                }
                titulo="Vincular Disciplina"
                label="Selecione a Disciplina para Vincular ao Curso"
                tipo={TipoDisciplina.Curso}
              />
              <ModalVincular
                id="modal-vincular-tcc"
                ref={modalDisciplinaTCCRef}
                acaoPrimaria={dados => {
                  adicinaDisciplinaTCCClick(dados)
                }}
                backdrop
                modalidade={
                  ehCursoLivreOuEvento
                    ? ModalidadeCurso.Livre
                    : ModalidadeCurso.Outra
                }
                titulo="Vincular Disciplina TCC"
                label="Selecione a Disciplina para Vincular ao TCC"
                tipo={TipoDisciplina.TCC}
              />
            </>
          )}
          <DesvincularDisciplinaCursoLivre
            ref={modalDesvincularDisciplinaCursoLivreRef}
            backdrop={true}
            acaoPrimaria={(index: number) => removerMaterial(index)}
          />
          <DesvincularDisciplina
            ref={modalDesvincularDisciplinaref}
            backdrop={true}
            acaoPrimaria={({ disciplina, indexModulo }) =>
              excluiDisciplinasClick(disciplina, indexModulo)
            }
          />
          <DesvincularDisciplina
            ref={modalDesvincularDisciplinaTCCref}
            backdrop={true}
            acaoPrimaria={({ disciplina }) =>
              excluiDisciplinasTCCClick(disciplinasVinculadasTCC, disciplina)
            }
          />
          <ModalIncluirGrade
            ref={modalIncluirGradeRef}
            backdrop
            decisao={existeGrade}
            id="modal-inclur-grade"
            acaoPrimaria={(dados: GradeCurricular) => {
              adicionarGrade(dados)
              defineExibirGrade(true)
              defineExisteGrade(true)
            }}
          />
          <ModalIncluirParcela
            ref={modalIncluirParcelaRef}
            backdrop
            id="modal-inclur-parcela"
            acaoPrimaria={editarParcela}
          />
          <ModalIncluirProrrogacao
            ref={modalIncluirProrrogacaoRef}
            backdrop
            id="modal-inclur-parcela"
            acaoPrimaria={adicionarProrrogacao}
          />
          <ModalCopiar
            ref={modalCopiarGradeRef}
            backdrop
            decisao={existeGrade}
            id="modal-copiar-grade"
            acaoPrimaria={dados => {
              RequisitarGrade(dados.grade)
            }}
          />
          <ModalIncluirPergunta
            backdrop
            ref={modalPerguntaRef}
            acaoPrimaria={adicionarPergunta}
            turma={false}
          />
          <ModalIncluirModulo
            id="modal-incluir-modulo"
            backdrop
            ref={modalModuloRef}
            acaoPrimaria={adicionarModulo}
          />
          <ModalEditarModulo
            id="modal-editar-modulo"
            backdrop
            ref={modalEditarModuloRef}
            acaoPrimaria={editarModulo}
          />
          <ModalIncluirModulo
            id="modal-incluir-modulo-tcc"
            backdrop
            ref={modalModuloTCCRef}
            acaoPrimaria={adicionarModuloTCC}
          />
          <ModalIncluirPlanoReingresso
            ref={modalIncluirPlanoReingresso}
            id="modal-incluir-plano-reingresso"
            backdrop
            acaoPrimaria={adicionarPlanoPagamentoReingresso}
          />
          <ModalExcluirMaterial
            backdrop
            ref={modalExcluirMaterialRef}
            acaoPrimaria={(index: number) => removerMaterial(index)}
          />
        </Container>
      )}
    </>
  )
}

export default withRouter(PaginaCursos)
