import React, { ChangeEventHandler, FC, useMemo, useRef, useState } from 'react'
import { toast, ToastOptions } from 'react-toastify'

import {
  IconeDeEdicaoDeUpload,
  IconeDeUploadDeDocumento,
  IconeDeAdicionarDocumento,
  Informacao,
  Tooltip,
  Cores,
  IconeDeDocumentoAprovado,
  IconeDeDocumentoRecusado,
  IconeRevelarSenha,
  IconeCarregando,
  Input
} from 'src/componentes'
import { TiposArquivosAceitos } from 'src/componentes/input/input-arquivo'
import { formatadores } from 'src/paginas/aluno/painel-financeiro/pagina-financeiro/formatadores'
import { DataNascimentoDocumento, SituacaoUpload } from 'src/tipos'

import { TipoDocumentoUpload, UploadDocumentos } from '../../tipos'
import {
  ModalAlteracaoDocAprovado,
  ModalAlteracaoDocAprovadoRef
} from '../modal-alteracao-aprovado'
import { StatusDeEnvio } from '../status-de-envio'
import {
  Container,
  NomeDoDocumento,
  StatusDoDocumento,
  OpcoesDeAcao,
  BotaoDeAcao,
  ContainerDoAnexo,
  IconeDoAnexo,
  TipoDoAnexo,
  ContainerColacaoGrau
} from './style'

const DICA_ID = 'DICA_ID'
const EDICAO_ID = 'EDICAO_ID'
const DOWNLOAD_ID = 'DOWNLOAD_ID'
const VISUALIZAR_ID = 'VISUALIZAR_ID'
const APROVACAO_ID = 'APROVACAO_ID'
const REJEICAO_ID = 'REJEICAO_ID'

const dicaDeEdicao = 'Alterar documento'
const dicaDeVisualizacao = 'Visualizar documento'
const dicaDeDownload = 'Realizar download'
const dicaDeAprovacao = 'Aprovar documento'
const dicaDeRejeicao = 'Recusar documento'
const dicaDataNascimentoDesabilitada =
  'Este campo é preenchido pela equipe iPGS ao fazer a conferência da certidão'

const extensoesPermitidas = ['pdf', 'png', 'jpg', 'jpeg']
const toastOptions = { type: 'error' } as ToastOptions

const extensaoNaoPermitida = `Extensão de arquivo não permitida. Extensões válidas para os seguintes formatos: ${extensoesPermitidas.join(
  ', '
)}`

export interface ControleDeAprovacao {
  ativo: boolean
  eventoDeAprovacao: (tipo: any) => void
  eventoDeRejeicao: (tipo: any) => void
}

export interface UploadDeDocumentosProps {
  documento: UploadDocumentos
  eventoDeDownload: (documentoId: string) => Promise<void>
  eventoDeVisualizacao: (documentoId: string) => Promise<void>
  eventoDeMudanca: (documento: UploadDocumentos) => void
  controleDeAprovacao?: ControleDeAprovacao
  exibirDataColacaoGrau?: boolean
  dataColacaoGrau?: string
  eventoAtualizacaoColacaoGrau?: (data: string) => void
  tiposAceitos?: TiposArquivosAceitos[] | null
  exibirDataNascimento?: boolean
  dataNascimento?: string
  eventoAtualizacaoDataNascimento?: (data: DataNascimentoDocumento) => void
}

type TipoDeEventoDeUpload = ChangeEventHandler<HTMLInputElement> & {
  target: HTMLInputElement & EventTarget
}

export const UploadDeDocumentos: FC<UploadDeDocumentosProps> = ({
  documento,
  eventoDeDownload,
  eventoDeVisualizacao,
  eventoDeMudanca,
  controleDeAprovacao = {
    ativo: false,
    eventoDeAprovacao: () => {},
    eventoDeRejeicao: () => {}
  },
  exibirDataColacaoGrau,
  dataColacaoGrau,
  eventoAtualizacaoColacaoGrau,
  tiposAceitos,
  dataNascimento,
  exibirDataNascimento,
  eventoAtualizacaoDataNascimento
}) => {
  const {
    id,
    documentoId,
    dataPostagem,
    editado,
    nome,
    tipo,
    obrigatorio,
    status,
    dica,
    motivoDaRejeicao,
    dataConferencia,
    editarAprovado
  } = documento

  const uploadRef = useRef<HTMLInputElement>()
  const modalAlteracaoDocAprovadoRef = useRef<ModalAlteracaoDocAprovadoRef>()
  const [carregando, definirCarregando] = useState<boolean>(false)
  const [motivoEdicaoAprovado, definirMotivoEdicaoAprovado] = useState<string>(
    null
  )

  const upload = (event: unknown) => {
    const evento = event as TipoDeEventoDeUpload
    const arquivo = evento.target.files[0]

    if (arquivo) {
      const possuiExtensaoPermitida = extensoesPermitidas.some(tipo =>
        arquivo.type.includes(tipo)
      )

      if (possuiExtensaoPermitida) {
        eventoDeMudanca({
          ...documento,
          status: SituacaoUpload.PendenteEnvio,
          nome: arquivo.name,
          editado: Boolean(documentoId),
          arquivo,
          motivoAlteracaoAprovado: motivoEdicaoAprovado
        })
      } else {
        toast(extensaoNaoPermitida, toastOptions)
      }
    }

    definirMotivoEdicaoAprovado(null)
  }

  const edicao = () => {
    uploadRef.current?.click()
  }

  const edicaoAprovado = () => {
    modalAlteracaoDocAprovadoRef?.current?.abrir()
  }

  const edicaoAprovadoSucesso = async (motivo: string) => {
    definirMotivoEdicaoAprovado(motivo)
    edicao()
  }

  const deveDesabilitarBotaoDeDownload = (status: SituacaoUpload) => {
    const pendenteDeEnvio = status === SituacaoUpload.PendenteEnvio
    const aguardandoAnaliseSemDocumentoId =
      status === SituacaoUpload.AguardandoAnalise && !documentoId
    const aguardandoAnalliseComEdicao =
      status === SituacaoUpload.AguardandoAnalise && editado

    return [
      pendenteDeEnvio,
      aguardandoAnaliseSemDocumentoId,
      aguardandoAnalliseComEdicao
    ].some(condicao => condicao)
  }

  const bloquearBotoesDeAcaoRecusa = useMemo(
    () =>
      !documentoId ||
      editado ||
      status === SituacaoUpload.PendenteEnvio ||
      status === SituacaoUpload.Recusado,
    [status, documentoId, editado]
  )

  const bloquearBotoesDeAcaoAceite = useMemo(
    () =>
      !documentoId ||
      editado ||
      status === SituacaoUpload.Recusado ||
      status === SituacaoUpload.Aprovado ||
      status === SituacaoUpload.PendenteEnvio,
    [status, documentoId, editado]
  )

  const obterTipo = useMemo(
    () => (tiposAceitos ? tiposAceitos.join(',') : undefined),
    [tiposAceitos]
  )

  const bloquearAtualizacaoAprovado = useMemo(() => {
    if (editarAprovado) return false

    return status === SituacaoUpload.Aprovado
  }, [editarAprovado, status])

  const obterNomeDoDocumento = () => {
    const inputId = id.toString()
    const dicaId = `${DICA_ID}/${inputId}`
    const dataPostagemDocumento = formatadores.dataLocaleString(dataPostagem)

    return (
      <ContainerDoAnexo htmlFor={inputId}>
        {nome ? (
          <NomeDoDocumento desabilitado={status === SituacaoUpload.Aprovado}>
            <p>{nome}</p>
            <p title="Envio">
              {dataPostagemDocumento} - {TipoDocumentoUpload[tipo]}
            </p>
          </NomeDoDocumento>
        ) : (
          <>
            <IconeDoAnexo>{IconeDeAdicionarDocumento}</IconeDoAnexo>
            <TipoDoAnexo>Anexar {TipoDocumentoUpload[tipo]}</TipoDoAnexo>
            {obrigatorio && (
              <>
                <div data-tip={dica} data-for={dicaId}>
                  <Informacao />
                </div>
                <Tooltip
                  id={dicaId}
                  place={'right'}
                  textColor={Cores.PRETO}
                  backgroundColor={Cores.CINZA_1_CLARO}
                  html={true}
                />
              </>
            )}
          </>
        )}
        <input
          type="file"
          id={inputId}
          onChange={upload}
          ref={uploadRef}
          disabled={bloquearAtualizacaoAprovado}
          accept={obterTipo}
        />
      </ContainerDoAnexo>
    )
  }

  const visualizacao = async () => {
    try {
      definirCarregando(true)
      await eventoDeVisualizacao(documentoId)
    } finally {
      definirCarregando(false)
    }
  }

  const download = async () => {
    try {
      definirCarregando(true)
      await eventoDeDownload(documentoId)
    } finally {
      definirCarregando(false)
    }
  }

  return (
    <Container>
      {obterNomeDoDocumento()}
      <StatusDoDocumento>
        <StatusDeEnvio
          id={id}
          tipo={status}
          motivoDaRejeicao={motivoDaRejeicao}
        />
        {dataConferencia && (
          <p title="Conferência">
            {formatadores.dataLocaleString(dataConferencia)}
          </p>
        )}
      </StatusDoDocumento>
      <OpcoesDeAcao>
        <BotaoDeAcao
          onClick={visualizacao}
          status={status}
          disabled={status === SituacaoUpload.PendenteEnvio || carregando}
        >
          <div data-tip={dicaDeVisualizacao} data-for={VISUALIZAR_ID}>
            {carregando ? IconeCarregando : IconeRevelarSenha}
          </div>
          <Tooltip
            id={VISUALIZAR_ID}
            place={'top'}
            textColor={Cores.PRETO}
            backgroundColor={Cores.CINZA_1_CLARO}
            html={true}
          />
        </BotaoDeAcao>
        <BotaoDeAcao
          onClick={status === SituacaoUpload.Aprovado ? edicaoAprovado : edicao}
          status={status}
          disabled={
            bloquearAtualizacaoAprovado ||
            (!nome && status === SituacaoUpload.PendenteEnvio)
          }
        >
          <div data-tip={dicaDeEdicao} data-for={EDICAO_ID}>
            {IconeDeEdicaoDeUpload}
          </div>
          <Tooltip
            id={EDICAO_ID}
            place={'top'}
            textColor={Cores.PRETO}
            backgroundColor={Cores.CINZA_1_CLARO}
            html={true}
          />
        </BotaoDeAcao>
        <BotaoDeAcao
          onClick={download}
          status={status}
          disabled={deveDesabilitarBotaoDeDownload(status) || carregando}
        >
          <div data-tip={dicaDeDownload} data-for={DOWNLOAD_ID}>
            {carregando ? IconeCarregando : IconeDeUploadDeDocumento}
          </div>
          <Tooltip
            id={DOWNLOAD_ID}
            place={'top'}
            textColor={Cores.PRETO}
            backgroundColor={Cores.CINZA_1_CLARO}
            html={true}
          />
        </BotaoDeAcao>
        {controleDeAprovacao.ativo && (
          <>
            <BotaoDeAcao
              onClick={() => controleDeAprovacao.eventoDeAprovacao(id)}
              status={status}
              disabled={bloquearBotoesDeAcaoAceite}
            >
              <div data-tip={dicaDeAprovacao} data-for={APROVACAO_ID}>
                {IconeDeDocumentoAprovado}
              </div>
              <Tooltip
                id={APROVACAO_ID}
                place={'top'}
                textColor={Cores.PRETO}
                backgroundColor={Cores.CINZA_1_CLARO}
                html={true}
              />
            </BotaoDeAcao>
            <BotaoDeAcao
              onClick={() => controleDeAprovacao.eventoDeRejeicao(id)}
              status={status}
              disabled={bloquearBotoesDeAcaoRecusa}
            >
              <div data-tip={dicaDeRejeicao} data-for={REJEICAO_ID}>
                {IconeDeDocumentoRecusado}
              </div>
              <Tooltip
                id={REJEICAO_ID}
                place={'top'}
                textColor={Cores.PRETO}
                backgroundColor={Cores.CINZA_1_CLARO}
                html={true}
              />
            </BotaoDeAcao>
          </>
        )}
        {exibirDataColacaoGrau && (
          <ContainerColacaoGrau>
            <Input
              className="sm"
              id="dataColacao"
              name="dataColacao"
              label="Data da Colação de Grau"
              placeholder="__/__/____"
              mascara="99/99/9999"
              disabled={!eventoAtualizacaoColacaoGrau}
              value={dataColacaoGrau}
              onChange={evento => {
                const valor = evento.target.value
                eventoAtualizacaoColacaoGrau(valor)
              }}
            />
          </ContainerColacaoGrau>
        )}
        {exibirDataNascimento && (
          <ContainerColacaoGrau>
            <Input
              className="sm"
              id="dataNascimento"
              name="dataNascimento"
              label={
                <>
                  Data de Nascimento
                  <Informacao
                    textoTooltip={dicaDataNascimentoDesabilitada}
                    invertido
                  />
                </>
              }
              placeholder="__/__/____"
              mascara="99/99/9999"
              disabled={!eventoAtualizacaoDataNascimento}
              value={dataNascimento}
              onChange={evento => {
                const valor = evento.target.value
                eventoAtualizacaoDataNascimento({
                  documentoId: documento.documentoId,
                  dataNascimento: valor
                })
              }}
            />
          </ContainerColacaoGrau>
        )}
      </OpcoesDeAcao>
      <ModalAlteracaoDocAprovado
        ref={modalAlteracaoDocAprovadoRef}
        acaoSucesso={edicaoAprovadoSucesso}
        acaoSecundaria={() => definirMotivoEdicaoAprovado(null)}
        backdrop
      ></ModalAlteracaoDocAprovado>
    </Container>
  )
}
