import React, { useRef, useState, useEffect } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  AutenticacaoContainer,
  Botao,
  Carregando,
  ErroLayoutContainer,
  FormRef,
  FormUnform,
  IconeAtencao,
  PerguntaLinear,
  PerguntaParagrafo,
  PerguntaRespostaUnica,
  validarPesquisa
} from 'src/componentes'
import { Container as ContainerErroLayout } from 'src/componentes/erro-container-layout/styles'
import { RotasAluno } from 'src/rotas/aluno'
import { Api } from 'src/servicos'
import {
  PesquisaSatisfacao,
  PesquisaSatisfacaoResposta,
  PesquisaSatisfacaoRespostaPergunta,
  TipoPesquisaSatisfacaoPergunta
} from 'src/tipos'

import {
  Container,
  ContainerBotaoPesquisaSatisfacao,
  ContainerPergunta,
  Conteudo,
  MensagemAviso,
  SubTitulo,
  Titulo
} from './styles'
import { PaginaVisualizarPesquisaSatisfacaoInstitucionalProps } from './tipos'

const PesquisaSatisfacaoInstitucionalComponente: React.FC<PaginaVisualizarPesquisaSatisfacaoInstitucionalProps> = ({
  match
}) => {
  const history = useHistory()
  const formRef = useRef<FormRef>()
  const [
    pesquisaSatisfacao,
    definirPesquisaSatisfacao
  ] = useState<PesquisaSatisfacao>({} as PesquisaSatisfacao)
  const [pronto, definirPronto] = useState<boolean>(false)
  const [erro, definirErro] = useState<string | undefined>(undefined)
  const [carregando, definirCarregando] = useState<boolean>(false)
  const { usuario } = AutenticacaoContainer.useContainer()
  const carregarPesquisa = async () => {
    try {
      definirPronto(false)
      definirPesquisaSatisfacao({} as PesquisaSatisfacao)
      const dados = await Api.RequisitarPesquisaSatisfacao(match.params.id)
      definirPesquisaSatisfacao(dados)
    } catch {
      definirErro('Erro ao carregar pesquisa satisfação')
    } finally {
      definirPronto(true)
    }
  }

  const acaoSucesso = async (dados: any) => {
    try {
      definirCarregando(true)

      if (!validarPesquisa(formRef, dados)) return

      const respostas = pesquisaSatisfacao.perguntas.map(item => {
        if (item.tipo === TipoPesquisaSatisfacaoPergunta.Paragrafo) {
          return {
            perguntaId: item.id,
            respostaParagrafo: dados.perguntaParagrafo[item.id] ?? null
          } as PesquisaSatisfacaoRespostaPergunta
        } else {
          return {
            perguntaId: item.id,
            alternativaId: dados.pergunta[item.id]
          } as PesquisaSatisfacaoRespostaPergunta
        }
      })

      const payload = {
        pesquisaSatisfacaoId: pesquisaSatisfacao.id,
        respostas: respostas
      } as PesquisaSatisfacaoResposta

      await Api.EnviarRespostaPesquisaSatisfacaoInstitucional(payload)
      history.push(RotasAluno.Dashboard)
    } catch {
      toast('Erro ao salvar resposta pesquisa satisfação', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }

  const resolveMensagemSemestre = () => {
    const dataAtual = new Date()
    const mes = dataAtual.getMonth()
    const ano = dataAtual.getFullYear()
    const semestre = mes <= 5 ? '1' : '2'

    return `Você está acessando a Avaliação Institucional do ${semestre}º semestre de ${ano} da Faculdade iPGS.`
  }

  useEffect(() => {
    carregarPesquisa()
  }, [])

  return (
    <>
      {pronto && !erro ? (
        <>
          <Container>
            <Titulo>Pesquisa de Satisfação Institucional</Titulo>
            <SubTitulo>{pesquisaSatisfacao.nome}</SubTitulo>
            {/* ///////////////////// Ativar a partir de 30 dias,  15/04 ///////////////////////////////////
            <MensagemAviso>
              <p>Olá, {usuario.nome}. Tudo bem?</p>
              <p>{resolveMensagemSemestre()}</p>
              <p>
                Importante: a sua sala de aula ficará bloqueada até você
                responder a pesquisa e em seguida será liberada novamente.
              </p>
              <p>Chegou a hora de você fazer a diferença!</p>
              <p>
                A sua opinião é de extrema importância, para seguirmos com nosso
                propósito de impactar vidas e carreiras em saúde.
              </p>
              <p>
                A pesquisa é super rápida de responder, pedimos 5 minutos do seu
                tempo para contribuir conosco.
              </p>
              <p>
                Legenda para responder as questões: 5 - muito satisfeito, 4 -
                satisfeito, 3 - neutro, 2 - insatisfeito, 1 - muito insatisfeito
              </p>
              <p>Grande abraço!</p>
              <p>Equipe iPGS</p>
            </MensagemAviso> */}
            <FormUnform ref={formRef} acaoSucesso={acaoSucesso}>
              {pesquisaSatisfacao?.perguntas?.map(pergunta => (
                <ContainerPergunta
                  key={pergunta.id}
                  className={`pergunta[${pergunta.id}]`}
                >
                  {pergunta.tipo ===
                    TipoPesquisaSatisfacaoPergunta.Paragrafo && (
                    <PerguntaParagrafo
                      pergunta={pergunta}
                      name={`perguntaParagrafo[${pergunta.id}]`}
                    />
                  )}
                  {pergunta.tipo ===
                    TipoPesquisaSatisfacaoPergunta.EscalaLinear && (
                    <PerguntaLinear
                      pergunta={pergunta}
                      name={`pergunta[${pergunta.id}]`}
                    />
                  )}
                  {pergunta.tipo ===
                    TipoPesquisaSatisfacaoPergunta.RespostaUnica && (
                    <PerguntaRespostaUnica
                      pergunta={pergunta}
                      name={`pergunta[${pergunta.id}]`}
                    />
                  )}
                </ContainerPergunta>
              ))}
              <ContainerBotaoPesquisaSatisfacao>
                <Botao
                  id="btn-voltar"
                  texto="Voltar"
                  onClick={() => history.push(RotasAluno.Dashboard)}
                />
                <Botao
                  type="submit"
                  id="btn-salvar"
                  texto="Enviar"
                  carregando={carregando}
                />
              </ContainerBotaoPesquisaSatisfacao>
            </FormUnform>
          </Container>
        </>
      ) : !pronto && !erro ? (
        <Conteudo>
          <Carregando texto="Carregando Pesquisa de Satisfação ..." />
        </Conteudo>
      ) : (
        <Conteudo>
          <ContainerErroLayout>
            <div>
              {IconeAtencao}
              <p>{erro}</p>
              <div>
                <Botao
                  tema="Padrao"
                  texto="Tentar novamente"
                  onClick={carregarPesquisa}
                  tamanho="S"
                />
              </div>
            </div>
          </ContainerErroLayout>
        </Conteudo>
      )}
    </>
  )
}

export const PesquisaSatisfacaoInstitucional: React.FC<PaginaVisualizarPesquisaSatisfacaoInstitucionalProps> = props => {
  return (
    <ErroLayoutContainer.Provider>
      <PesquisaSatisfacaoInstitucionalComponente {...props} />
    </ErroLayoutContainer.Provider>
  )
}

export default withRouter(PesquisaSatisfacaoInstitucional)
