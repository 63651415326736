import { BoletoGerado, DadosMatricula } from 'src/servicos/api/tipos'

import { FormaPagamentoCobranca } from '.'
import { Curso } from './curso'
import { Endereco } from './endereco'
import {
  MetodosPagamento,
  Nacionalidade,
  Raca,
  TipoDescontoCalculo,
  StatusCarrinhoCompra,
  TipoTransferenciaAluno,
  TipoUsuario,
  SituacaoResponsavelFinanceiro,
  OpcaoAlteracaoTCC
} from './enums'
import { SituacaoUpload } from './resumo-de-documentos'
import { Usuario } from './usuario'

export interface DadosPessoaisMatricula {
  nacionalidade: keyof typeof Nacionalidade
  cpf?: string
  documentoIdentidade?: string
  codigoConselho?: string
  nome: string
  possuiNomeSocial?: boolean
  nomeSocial?: string
  dataNascimento?: string
  mesAnoColacaoGrau?: string
  corRaca?: keyof typeof Raca
  email: string
  celular: string
  escolaridade?: string
  profissao?: string
  endereco?: Partial<Endereco>

  formaPagamentoTaxaMatricula?: string
  idCupomTaxaMatricula?: string
  dataVencimentoTaxaMatricula?: string
  codigoCupomTaxaMatricula?: string

  idBoletoTaxaMatricula?: string
  linhaDigitavelTaxaMatricula?: string
  linkBoletoTaxaMatricula?: string
  qrCode?: string
}

export interface TermosCondicoesMatricula {
  urlTermosCondicoes?: string
  liAceitoTermos: boolean
  estouCiente: boolean
}

export interface PagamentoMatricula {
  metodoPagamento: MetodosPagamento
  numeroCartao?: string
  nomeCartao?: string
  validadeCartao?: string
  codigoSeguranca?: string
  parcelas?: string
  diaVencimento?: string
  idCupom?: string
  codigoCupom?: string
  valor?: number
  valorDesconto?: number
  valorTotal?: number
  boletoGerado?: BoletoGerado
}

export interface UsuarioResponsavelFinanceiroMatriculaForm {
  email: string
  tipo: TipoUsuario
  cpfCnpj: string
  documentoIdentidade?: string
  celular: string
  nome: string
  enderecoDiferente: boolean
  endereco: Endereco
  nacionalidade?: keyof typeof Nacionalidade
  inscricaoEstadual?: string
  inscricaoMunicipal?: string
}

export interface ResponsavelFinanceiroMatriculaForm {
  possuiResponsavelFinanceiro: boolean
  idUsuarioResponsavel?: string
  usuarioResponsavel?: UsuarioResponsavelFinanceiroMatriculaForm
  enderecoUsuarioMatricula: boolean
}

export interface PlanoPagamentoMatricula {
  planoPagamentoId?: string
  diaVencimento?: string
  idCupom?: string
  codigoCupom?: string
  valorDesconto?: number
  valorTotal?: number
  valorParcelado?: number
  valorParcela?: number
  quantidadeParcelas?: number
  pagamentoRecorrente?: boolean
  formPagamentoParcela: FormaPagamentoCobranca
  responsavelFinanceiro?: ResponsavelFinanceiro
}

export interface PlanoPagamentoMatriculaForm
  extends Omit<PlanoPagamentoMatricula, 'responsavelFinanceiro'> {
  responsavelFinanceiro?: UsuarioResponsavelFinanceiroMatriculaForm
}

export enum EtapasMatriculaCursoLivre {
  DadosPessoais = 1,
  DadosPagamento = 2,
  AguardandoPagamento = 3,
  Concluida = 4
}

export enum EtapasMatriculaOutros {
  DadosPessoais = 1,
  DadosPlanoPagamento = 2,
  TermosECondicoes = 3,
  DadosPagamento = 4,
  AguardandoPagamento = 5,
  Concluida = 6
}

export enum SituacaoMatricula {
  Rascunho = 'Rascunho',
  Reservada = 'Reservada',
  Finalizada = 'Finalizada'
}

export interface TransferenciaAluno {
  matriculaOrigemId: string
  matriculaDestinoId: string
  tipoTransferencia: TipoTransferenciaAluno
  taxaIsenta: boolean
}

export interface ResponsavelFinanceiro {
  possuiResponsavelFinanceiro: boolean
  idUsuarioResponsavel?: string
  enderecoUsuarioMatricula?: boolean
  usuarioResponsavel?: Usuario
}

export interface Matricula {
  id?: string
  etapaMatricula:
    | keyof typeof EtapasMatriculaCursoLivre
    | keyof typeof EtapasMatriculaOutros
  situacaoMatricula: SituacaoMatricula
  dadosPessoais?: DadosPessoaisMatricula
  termosCondicoes?: TermosCondicoesMatricula
  pagamentoMatricula?: PagamentoMatricula
  planoPagamento?: PlanoPagamentoMatricula
  cursoId?: string
  transferenciaAluno?: TransferenciaAluno
  responsavelFinanceiro?: ResponsavelFinanceiro
}

export interface CupomValido {
  codigoCupom: string
  cupomId: string
  valorDesconto: number
  valorFinal: number
}

export interface CupomValidoCurso extends CupomValido {
  cursoId?: string
}

export enum SituacaoFinanceiraPorExtenso {
  Reservado = 'Reservado',
  Inativo = 'Inativo',
  Matriculado = 'Matriculado',
  ContratoRescindido = 'Contrato Rescindido',
  Cancelado = 'Cancelado',
  Transferido = 'Transferido',
  Finalizado = 'Finalizado',
  Reprovado = 'Reprovado',
  AguardandoCartaoPagamentoRecorrente = 'Aguardando Cartão Pagamento Recorrente',
  Recuperacao = 'Recuperação'
}

export interface CalculoDescontoRenegociacao {
  valorParcelas?: number
}

export interface RenegociacaoMatricula {
  matriculaId?: string
}

export interface Renegociacoes {
  quantidade: number
  valorParcela: number
  tipoValorDesconto: TipoDescontoCalculo
  valorDesconto: number
  valorTotal: number
  observacao: string
  primeiroVencimento: Date
  ignorarMultaJuros: boolean
}

export interface ModalRenegociacaoDados {
  renegociacoes: Renegociacoes[]
}
export interface CarrinhoCompraCursoLivre {
  carrinhoCompraId: string
  status: StatusCarrinhoCompra
  matriculas: DadosMatricula[]
  cursos: Curso[]
  boletoGerado: boolean
}

interface DadosTurmaTransferencia {
  turmaId: string
  codigoTurma: string
}

export interface OpcoesTransferenciaAluno {
  turmas: DadosTurmaTransferencia[]
}

export interface NovaMatriculaTurma {
  turmaId: string
  nomeTurma: string
  codigoTurma: string
}
export interface NovaMatriculaTurmas {
  turmas: NovaMatriculaTurma[]
}
export interface ContratosAdendo {
  id: string
  nome: string
}

export interface ResumoContratos {
  matriculaId: string
  adendos: ContratosAdendo[]
}

export interface AceiteResponsavelFinanceiro {
  matriculaId: string
  situacaoResponsavel?: SituacaoResponsavelFinanceiro
  motivoSituacao?: string
}

export interface AceiteDocumentoResponsavelFinanceiro {
  documentoId: string
  situacao: SituacaoUpload
  motivoRejeicao?: string
}

export interface PlanoPagamentoParcelasTransferencia {
  id?: string
  valorParcela: number
  quantidadeParcelas: number
  formaPagamento: string
}

export interface OpcoesAlteracaoTCCDto {
  opcoes: OpcaoAlteracaoTCC[]
}

export interface InfoRecuperacao {
  solicitacao: SolicitacaoRecuperacao
  valorRecuperacao: number
  tempoRecuperacao: number
  quantidadeMaximaParcelas: number
  disciplinas: InfoDisciplinaOuModulo[]
  modulos: InfoDisciplinaOuModulo[]
  nomeCurso: string
  quantidade: number
  reingressoId?: string
}

export interface InfoDisciplinaOuModulo {
  nome: string
  nota: number
}

export enum SolicitacaoRecuperacao {
  Indisponivel = 'Indisponivel',
  FinanceiroPendente = 'FinanceiroPendente',
  ForaDoPrazo = 'ForaDoPrazo',
  QuantidadeItensInvalida = 'QuantidadeItensInvalida',
  Disponivel = 'Disponivel'
}

export interface RecuperacaoManual {
  solicitacao: SolicitacaoRecuperacaoManual
  valorRecuperacao: number
  tempoRecuperacao: number
  quantidadeMaximaParcelas: number
  nomeCurso: string
  quantidade: number
  disciplinas: InfoDisciplinaOuModulo[]
  modulos: InfoDisciplinaOuModulo[]
  dataFimRecuperacao: Date
}

export enum SolicitacaoRecuperacaoManual {
  Disponivel = 'Disponivel',
  Indisponivel = 'Indisponivel',
  FinanceiroPendente = 'FinanceiroPendente'
}

export enum TipoRecorrencia {
  Padrao = 'Padrao',
  NegociacaoEspecial = 'NegociacaoEspecial',
  TrocaParcelamento = 'TrocaParcelamento',
  CartaoVencido = 'CartaoVencido',
  Transferencia = 'Transferencia'
}

export enum TipoRecorrenciaAcaoPorExtenso {
  'Padrao' = 'Padrão',
  'NegociacaoEspecial' = 'Aguardando pagamento - Negociação especial',
  'TrocaParcelamento' = 'Aguardando pagamento - Troca de parcelamento',
  'CartaoVencido' = 'Aguardando pagamento - Cartão vencido',
  'Transferencia' = 'Aguardando pagamento - Transferência'
}

export enum TipoRecorrenciaAcaoPorExtensoSimples {
  'Padrao' = 'Padrão',
  'NegociacaoEspecial' = 'Negociação especial',
  'TrocaParcelamento' = 'Troca de parcelamento',
  'CartaoVencido' = 'Cartão vencido',
  'Transferencia' = 'Transferência'
}
