import {
  SituacaoUpload,
  TipoDocumentoAluno,
  TipoDocumentoProfessor,
  TipoDocumentoResponsavelFinanceiro
} from 'src/tipos'

export enum TipoDocumentoUpload {
  RegistroCivil = 'Registro Civil',
  Rg = 'RG - Frente',
  RgVerso = 'RG - Verso',
  Rne = 'Registro Nacional de Estrangeiros',
  Cpf = 'CPF',
  DiplomaGraduacao = 'Diploma de Graduação - Frente',
  DiplomaGraduacaoVerso = 'Diploma de Graduação - Verso',
  ComprovanteResidencia = 'Comprovante de Residência',
  CertificadoReservista = 'Certificado de Reservista',
  HistoricoEscolar = 'Histórico da Graduação',
  DiplomaTitulacaoMaxima = 'Diploma de Titulação Máx. - Frente',
  DiplomaTitulacaoMaximaVerso = 'Diploma de Titulação Máx. - Verso',
  RgCnh = 'RG/CNH - Frente',
  RgCnhVerso = 'RG/CNH - Verso',
  Identificacao = 'Documento de Identidade (ou CNPJ)',
  CertidaoNascimento = 'Certidão de nascimento'
}

export interface UploadDocumentos {
  id: number | string
  documentoId?: string
  nome?: string
  tipo:
    | TipoDocumentoAluno
    | TipoDocumentoProfessor
    | TipoDocumentoResponsavelFinanceiro
  obrigatorio?: boolean
  dica?: string
  status: SituacaoUpload
  arquivo?: File
  editado?: boolean
  analisado?: boolean
  motivoDaRejeicao?: string
  dataPostagem?: string
  ordemExibicao: number
  dataConferencia?: string
  editarAprovado?: boolean
  motivoAlteracaoAprovado?: string
  multiplo?: boolean
  dataNascimento?: string
}

export interface AgregacaoDeUploads {
  edicoes: UploadDocumentos[]
  criacoes: UploadDocumentos[]
  analises?: UploadDocumentos[]
  contador: number
}
