import { TipoMaterialComplementar } from 'src/componentes'
import {
  DadosDoCartaoDeCredito,
  FormaPagamentoCobranca,
  OpcaoTCC,
  TipoProfessorModuloTCC
} from 'src/tipos'

import { ModalidadeCurso, TipoAvaliacao, TipoRecuperacao } from '../curso'
import {
  SalaAula,
  SalaAulaModulo,
  SalaAulaQuestaoResposta,
  SalaAulaTCCModulo,
  SalaAulaTipoMaterial
} from './comum'

export interface SalaAulaOutros extends SalaAula {
  id: string
  turmaId: string
  dataInicio: string
  informacoesGeraisCurso?: string
  nomeCurso: string
  cargaHoraria: number
  duracaoCurso: number
  progresso: number
  aprovado: boolean
  dataAprovacao?: string
  dataLiberacao: string
  media: number
  primeiroMaterialNaoLido: string
  modulos: SalaAulaModulo[]
  materiaisTurma?: SalaAulaMaterialTurma[]
  turmaNome: string
  localTurma?: string
  dataInicioTurma?: string
  dataFimTurma?: string
  modalidadeCurso: ModalidadeCurso
  moduloTCC: SalaAulaTCCModulo
  cursoPossuiTCC: boolean
  opcaoTCC?: OpcaoTCC
  dataLiberacaoOpteTCC: string
  dataBloqueioSalaAula: string
  pesquisaSatisfacaoInstitucionalId?: string
  materiaisComplementares?: SalaAulaMaterialComplementar[]
  podeSolicitarProrrogacaoDataLiberacao: boolean
  podeSolicitarProrrogacaoDataLimite: boolean
  podeSolicitarProrrogacaoPendencias: boolean
  turmaSoCertificacao: boolean
  possuiProrrogacao: boolean
  tempoMaximoProrrogacao: number
  dataLiberacaoPedidoProrrogacao: string
  dataLimitePedidoProrrogacao: string
  dataFimSalaAula: string
  tipoAvaliacao: TipoAvaliacao
  podeSolicitarTCC: boolean
  periodoRecuperacao: boolean
  tipoRecuperacao: TipoRecuperacao
  disciplinasComplementares?: SalaAulaEadDisciplinaComplementar[]
  reingresso: boolean
  exigirCartao?: boolean
  permitidoTrocaModuloFlex?: boolean
  orientacaoEmAndamento?: boolean
}

export interface SalaAulaMaterialTurma {
  id: string
  titulo: string
  extensao?: string
  url?: string
}

interface SalaAulaOutrosPergunta {
  id: string
  texto: string
  dataLiberacao: string
  dataLimite: string
  alternativas: {
    id: string
    texto: string
  }[]
}

export interface SalaAulaOutrosAvaliacaoArquivoNota {
  id: string
  professorId: string
  professorNome: string
  usuarioProfessorId: string
  nota: number
  feedBack?: string
  statusCorrecao?: StatusCorrecao
  tipoProfessor?: TipoProfessorModuloTCC
}

export interface SalaAulaOutrosEnvioAvaliacaoArquivo {
  id: string
  tentativa: number
  urlCaminhoArquivo: string
  dataHoraEnvioAvaliacao?: string
  podeAvaliarTCC: boolean
  notas: SalaAulaOutrosAvaliacaoArquivoNota[]
}

export interface SalaAulaOutrosArquivo {
  id: string
  urlCaminhoArquivo: string
  dataHoraEnvioAvaliacao?: string
  nota: number
  feedBackProfessor?: string
  dataLiberacao: string
  dataLimite: string
  envios: SalaAulaOutrosEnvioAvaliacaoArquivo[]
}

export interface SalaAulaOutrosAvaliacao {
  id: string
  nomeAvaliacao: string
  descricaoAvaliacao?: string
  quantidadeMaximaTentativasAvaliacao: number
  quantidadeTentativasAvaliacaoRealizada: number
  perguntas?: SalaAulaOutrosPergunta[]
  arquivos?: SalaAulaOutrosArquivo[]
  notaAproveitamento?: number
  notaAproveitada: boolean
  ultimaTentativaAvaliacao?: {
    nota: number
    respostas: SalaAulaQuestaoResposta[]
  }
  dataUltimaTentativaAvaliacao?: string
  ultimaTentativa: boolean
  dataLimiteAvaliacao: string
  nota?: number
}

export interface SalaAulaOutrosAceiteTCC {
  quantidadeParcelasTCC?: number
  orientadorTCC: string
  coorientadorTCC: string
  diaVencimento?: number
  opcaoTCC: OpcaoTCC
  cupomId?: string
  metodoPagamento?: FormaPagamentoCobranca
  dadosCartao?: DadosDoCartaoDeCredito
}

export interface NotaFeedBackAvaliacaoTCC {
  id: string
  professorId: string
  usuarioProfessorId: string
  professorNome: string
  nota: number
  feedBack: string
  tipoProfessor: TipoProfessorModuloTCC
}

export interface FeedBackAvaliacaoTCC {
  id: string
  envios: SalaAulaOutrosEnvioAvaliacaoArquivo[]
}
export interface NotasAvaliacaoTCC {
  feedbacks: FeedBackAvaliacaoTCC[]
}

export interface SalaAulaMaterialComplementar {
  id: string
  lido: boolean
  nomeMaterial: string
  ordem: number
  tipoMaterial: SalaAulaTipoMaterial
  extensao?: string
  linkMaterial: string
  tipoMaterialComplementar: TipoMaterialComplementar
}

export interface SalaAulaOutrosAceiteProrrogacao {
  tempoProrrogacao: number
  valorProrrogacao: number
  quantidadeParcelas: number
  diaVencimento: number
  opcaoProrrogacaoId: string
  formaPagamento: FormaPagamentoCobranca
  dadosCartao?: DadosDoCartaoDeCredito
}

export enum StatusCorrecao {
  AguardandoCorrecao = 'AguardandoCorrecao',
  Corrigido = 'Corrigido'
}

export enum StatusCorrecaoPorExtenso {
  AguardandoCorrecao = 'Aguardando correção',
  Corrigido = 'Corrigido'
}

export interface SalaAulaEadDisciplinaComplementar {
  tipo: TipoMaterialComplementar
  nome: string
}
