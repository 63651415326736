import { mensagemPadrao } from 'src/dados-estaticos'
import { ModalidadeCurso, TCCSituacao } from 'src/tipos'
import * as Yup from 'yup'
const caracter = Yup.string().required(mensagemPadrao)

const semEspacosECaracteresEspeciais = Yup.string()
  .matches(
    /^[a-zA-Z0-9]*$/,
    'Sigla não deve conter espaços em branco nem caracteres especiais'
  )
  .required(mensagemPadrao)

const avaliacaoComDadosPreenchidos = Yup.string().when(
  ['quantidadeTentativas', 'descricao'],
  {
    is: (quantidadeTentativas, descricao) =>
      !!quantidadeTentativas || !!descricao,
    then: field => field.required(mensagemPadrao)
  }
)
export const criarSchema = (possuiPerguntas?: boolean): Yup.ObjectSchema =>
  Yup.object().shape({
    sigla: semEspacosECaracteresEspeciais,
    nome: caracter,
    modalidade: caracter,
    duracao: Yup.number()
      .nullable()
      .transform(v => (v === '' || isNaN(v) ? null : v))
      .typeError('Digite um valor válido')
      .max(9999, 'Este campo não deve possuir valor maior que 9999'),
    valorTaxaMatricula: Yup.number().nullable().required(mensagemPadrao),
    quantidadeMaximaParcelas: Yup.number()
      .typeError(mensagemPadrao)
      .required(mensagemPadrao)
      .max(9999, 'Este campo não deve possuir valor maior que 9999'),
    informacoesIniciais: Yup.string().url('Url inválida').nullable(),
    informacoes: Yup.string().when('modalidade', {
      is: ModalidadeCurso.Livre || ModalidadeCurso.Evento,
      then: field => field.required(mensagemPadrao)
    }),
    tempoDisponibilidade: Yup.number()
      .nullable()
      .when('modalidade', {
        is: ModalidadeCurso.Livre || ModalidadeCurso.Evento,
        then: field => field.required(mensagemPadrao)
      }),
    cargaHoraria: Yup.string()
      .nullable()
      .required(mensagemPadrao)
      .matches(/^(0|[1-9]\d*)(\.\d+)?$/, 'Digite um valor válido')
      .typeError('Digite um valor válido'),
    tipoAvaliacao: Yup.string().when('modalidade', {
      is: value =>
        value !== ModalidadeCurso.Livre && value !== ModalidadeCurso.Evento,
      then: field => field.required(mensagemPadrao)
    }),
    contrato: Yup.string()
      .nullable()
      .when('modalidade', {
        is: value =>
          value !== ModalidadeCurso.Livre && value !== ModalidadeCurso.Evento,
        then: field => field.required(mensagemPadrao)
      }),
    avaliacao: Yup.object()
      .shape({
        nome: possuiPerguntas ? caracter : avaliacaoComDadosPreenchidos,
        descricao: Yup.string().nullable(),
        quantidadeTentativas: Yup.number()
          .transform(v => (v === '' || isNaN(v) ? null : v))
          .nullable()
          .min(1, 'Este campo deve ser maior que zero')
          .max(9999, 'Este campo não deve possuir valor maior que 9999')
      })
      .nullable()
      .when('modalidade', {
        is:
          (ModalidadeCurso.Livre || ModalidadeCurso.Evento) && possuiPerguntas,
        then: field => field.required(mensagemPadrao)
      }),
    precoBaseTCC: Yup.number()
      .nullable()
      .when('tcc', {
        is: value => value === TCCSituacao.Sim,
        then: field => field.required(mensagemPadrao)
      }),
    quantidadeMaximaParcelasTCC: Yup.number()
      .nullable()
      .when('tcc', {
        is: value => value === TCCSituacao.Sim,
        then: field =>
          field
            .typeError(mensagemPadrao)
            .required(mensagemPadrao)
            .max(9999, 'Este campo não deve possuir valor maior que 9999')
      }),
    tempoMaximoProrrogacao: Yup.number()
      .nullable()
      .when('modalidade', {
        is: modalidadeCurso =>
          modalidadeCurso === ModalidadeCurso.PosGraduacaoEad ||
          modalidadeCurso === ModalidadeCurso.PosGraduacaoEadFull ||
          modalidadeCurso === ModalidadeCurso.PosGraduacaoPresencial,
        then: field =>
          field
            .typeError(mensagemPadrao)
            .max(9999, 'Este campo não deve possuir valor maior que 9999')
      }),
    dataInicio: Yup.string()
      .nullable()
      .when('modalidade', {
        is: ModalidadeCurso.Livre || ModalidadeCurso.Evento,
        then: field => field.required(mensagemPadrao)
      }),
    valorRecuperacao: Yup.number()
      .nullable()
      .when('modalidade', {
        is: modalidadeCurso =>
          modalidadeCurso === ModalidadeCurso.PosGraduacaoEad ||
          modalidadeCurso === ModalidadeCurso.PosGraduacaoEadFull,
        then: field => field.required(mensagemPadrao)
      }),
    tempoRecuperacao: Yup.number()
      .nullable()
      .when('modalidade', {
        is: modalidadeCurso =>
          modalidadeCurso === ModalidadeCurso.PosGraduacaoEad ||
          modalidadeCurso === ModalidadeCurso.PosGraduacaoEadFull,
        then: field => field.required(mensagemPadrao)
      }),
    quantidadeMaximaParcelasRecuperacao: Yup.number()
      .nullable()
      .when('modalidade', {
        is: modalidadeCurso =>
          modalidadeCurso === ModalidadeCurso.PosGraduacaoEad ||
          modalidadeCurso === ModalidadeCurso.PosGraduacaoEadFull,
        then: field => field.required(mensagemPadrao)
      }),
    precoBaseReingresso: Yup.number()
      .nullable()
      .when('modalidade', {
        is: modalidadeCurso =>
          modalidadeCurso === ModalidadeCurso.PosGraduacaoEad ||
          modalidadeCurso === ModalidadeCurso.PosGraduacaoEadFull,
        then: field => field.required(mensagemPadrao)
      }),
    valorTaxaReingresso: Yup.number()
      .nullable()
      .when('modalidade', {
        is: modalidadeCurso =>
          modalidadeCurso === ModalidadeCurso.PosGraduacaoEad ||
          modalidadeCurso === ModalidadeCurso.PosGraduacaoEadFull,
        then: field => field.required(mensagemPadrao)
      }),
    quantidadeMaximaParcelasReingresso: Yup.number()
      .nullable()
      .when('modalidade', {
        is: modalidadeCurso =>
          modalidadeCurso === ModalidadeCurso.PosGraduacaoEad ||
          modalidadeCurso === ModalidadeCurso.PosGraduacaoEadFull,
        then: field => field.required(mensagemPadrao)
      }),
    quantidadeModuloFlexiveis: Yup.number()
      .nullable()
      .when('modalidade', {
        is: modalidadeCurso =>
          modalidadeCurso === ModalidadeCurso.PosGraduacaoEadFlex,
        then: field => field.required(mensagemPadrao)
      })
  })
