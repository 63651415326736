export const mensagemPadrao = 'Este campo deve ser preenchido!'
export * from './escolaridade'
export * from './estados'
export * from './dias'
export * from './metodos-pagamento'
export * from './modalidades'
export * from './nacionalidades'
export * from './paises'
export * from './raca'
export * from './utilizacao-cupom'
export * from './sexo'
export * from './estado-civil'
export * from './tipo-usuario'
