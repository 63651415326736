/* eslint-disable indent */
import React, { useState, useMemo, useRef, useCallback, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Botao,
  BotaoStep,
  IconeCartaoAmex,
  IconeCartaoMaster,
  IconeCartaoVisa,
  ModalRef,
  PdfViewer,
  FormUnform,
  FormRef,
  InputUnform,
  SelectUnform,
  WizardContainer,
  FuncoesMoeda,
  InputMesAnoUnform,
  IconeInformacao,
  Tooltip,
  Cores,
  scrollParaTopo,
  CartaoCreditoInputUnform,
  focarPrimeiroCampoComErro,
  IconeCartaoElo,
  IconeCartaoHiper,
  IconeCartaoJcb,
  IconeCartaoDiners,
  regExpDiners,
  regExpJcb,
  regExpHiper,
  regExpElo,
  regExpAmex,
  regExpMaster,
  regExpVisa
} from 'src/componentes'
import {
  SELECT_DIAS_DIAS_BOLETO_AUTOMATICO,
  SELECT_METODOSPAGAMENTO
} from 'src/dados-estaticos'
import { Api } from 'src/servicos'
import { BoletoGerado, DadosCartao } from 'src/servicos/api/tipos'
import {
  PagamentoMatricula,
  CupomValidoCurso,
  MetodosPagamento,
  validaEhModalidadeLivreOuEvento
} from 'src/tipos'
import { v4 } from 'uuid'

import MatriculaContainer from '../../container'
import {
  schemaPagamentoBoleto,
  schemaPagamentoCartaoCredito,
  schemaPagamentoCartaoRecorrencia,
  schemaPagamentoPlanoIsento,
  schemaPagamentoPix
} from '../../schema'
import {
  ContainerConteudo,
  ContainerAcoes,
  ContainerCadastro,
  Linha,
  ContainerMedio,
  ContainerInfos,
  Info,
  TituloIsento,
  TituloIsentoRecorrencia,
  ContainerValorTotalCursos
} from '../../styles'
import { InfoValoresCurso } from './info-valores-curso'
import { ValorCurso } from './info-valores-curso/tipos'
import { ModalPagamentoMatricula } from './modal-pagamento-matricula'
import { ModalPagamentoCartaoRecorrente } from './modal-pagamento-recorrente'
import {
  LinhaBandeiras,
  ContainerBandeiras,
  ContainerBotaoBoleto,
  ContainerBoleto,
  ContainerBotaoPix,
  IconeLabel
} from './styles'

export const Pagamento: React.FC = () => {
  const modalRef = useRef<ModalRef>(null)
  const modalErroPagRecorrenteRef = useRef<ModalRef>(null)
  const formRef = useRef<FormRef>(null)
  const history = useHistory()

  const {
    curso,
    matricula,
    carrinhoCurso,
    obterCursoEstado,
    possuiCarrinho,
    definirPagamento,
    obterMatriculas,
    possuiMatriculaCarrinho,
    definirBoletoGeradoCarrinho,
    definirPagamentoMatriculasCarrinho
  } = MatriculaContainer.useContainer()
  const { voltarWizard, passo } = WizardContainer.useContainer()

  const [carregandoBoleto, definirCarregandoBoleto] = useState(false)
  const [carregandoFinalizar, definirCarregandoFinalizar] = useState(false)
  const [carregandoVoltar, definirCarregandoVoltar] = useState(false)

  const [cupons, definirCupons] = useState<CupomValidoCurso[]>(
    obterMatriculas().map(m => {
      return {
        cupomId: m.pagamentoMatricula?.idCupom,
        codigoCupom: m.pagamentoMatricula?.codigoCupom,
        valorDesconto: m.pagamentoMatricula?.valorDesconto,
        valorFinal: m.pagamentoMatricula?.valorTotal,
        cursoId: m.cursoId
      }
    })
  )

  const [metodoPagamento, definirMetedoPagamento] = useState(
    SELECT_METODOSPAGAMENTO[0].id
  )

  const [boleto, definirBoleto] = useState<BoletoGerado | undefined>(
    matricula.pagamentoMatricula?.boletoGerado || undefined
  )

  const [numeroCartao, definirNumeroCartao] = useState('')

  const [ehPagamentoRecorrente, definirEhPagamentoRecorrente] = useState(false)

  const [pixCopiaECola, definirPixCopiaECola] = useState('')

  const cursoEstado = useMemo(() => obterCursoEstado(), [curso, carrinhoCurso])

  const [valoresCursos, definirValoresCursos] = useState<ValorCurso[]>(
    possuiCarrinho()
      ? carrinhoCurso.cursos?.map(c => {
          return {
            cursoId: c.id,
            valor: c.valorTaxaMatricula ?? 0,
            valorOriginal: c.valorTaxaMatricula ?? 0
          } as ValorCurso
        }) ?? ([] as ValorCurso[])
      : ([
          {
            cursoId: cursoEstado?.id,
            valor: cursoEstado?.valorTaxaMatricula ?? 0,
            valorOriginal: cursoEstado.valorTaxaMatricula ?? 0
          }
        ] as ValorCurso[])
  )

  const [valoresDescontosCursos, definirValoresDescontosCursos] = useState<
    ValorCurso[]
  >([])

  const ehCursoLivreOuEvento = useMemo(() => {
    return validaEhModalidadeLivreOuEvento(cursoEstado.modalidade)
  }, [cursoEstado])

  const dadosIniciais = useMemo(() => {
    const matriculaAtual = obterMatriculas()

    if (matriculaAtual[0].pagamentoMatricula) {
      const {
        metodoPagamento,
        diaVencimento,
        numeroCartao,
        nomeCartao,
        validadeCartao,
        codigoSeguranca,
        parcelas
      } = matriculaAtual[0].pagamentoMatricula

      if (metodoPagamento === SELECT_METODOSPAGAMENTO[0].id) {
        return {
          metodoPagamento,
          diaVencimento,
          numeroCartao,
          nomeCartao,
          validadeCartao,
          codigoSeguranca,
          parcelas
        }
      }

      return {
        metodoPagamento,
        diaVencimento
      }
    }

    if (matriculaAtual[0]) {
      if (
        matriculaAtual[0].dadosPessoais.formaPagamentoTaxaMatricula ===
          SELECT_METODOSPAGAMENTO[1].id ||
        matriculaAtual[0].dadosPessoais.formaPagamentoTaxaMatricula ===
          SELECT_METODOSPAGAMENTO[2].id
      ) {
        definirBoleto({
          boletoId: matriculaAtual[0].dadosPessoais.idBoletoTaxaMatricula,
          linhaDigitavel:
            matriculaAtual[0].dadosPessoais.linhaDigitavelTaxaMatricula,
          linkBoleto: matriculaAtual[0].dadosPessoais.linkBoletoTaxaMatricula
        })
        return {
          metodoPagamento:
            matriculaAtual[0].dadosPessoais.formaPagamentoTaxaMatricula,
          diaVencimento: new Date(
            matriculaAtual[0].dadosPessoais.dataVencimentoTaxaMatricula
          )
            .getDate()
            .toString(),
          codigoCupom: matriculaAtual[0].dadosPessoais.codigoCupomTaxaMatricula,
          cupomId: matriculaAtual[0].dadosPessoais.idCupomTaxaMatricula
        }
      }
      if (
        matriculaAtual[0].dadosPessoais.formaPagamentoTaxaMatricula ===
        SELECT_METODOSPAGAMENTO[2].id
      ) {
        definirBoleto({
          boletoId: matriculaAtual[0].dadosPessoais.idBoletoTaxaMatricula,
          linhaDigitavel:
            matriculaAtual[0].dadosPessoais.linhaDigitavelTaxaMatricula,
          linkBoleto: matriculaAtual[0].dadosPessoais.linkBoletoTaxaMatricula,
          qrCode: matriculaAtual[0].dadosPessoais.qrCode
        })
        return {
          metodoPagamento:
            matriculaAtual[0].dadosPessoais.formaPagamentoTaxaMatricula,
          diaVencimento: new Date(
            matriculaAtual[0].dadosPessoais.dataVencimentoTaxaMatricula
          )
            .getDate()
            .toString(),
          codigoCupom: matriculaAtual[0].dadosPessoais.codigoCupomTaxaMatricula,
          cupomId: matriculaAtual[0].dadosPessoais.idCupomTaxaMatricula
        }
      }
    }
    return {
      metodoPagamento: SELECT_METODOSPAGAMENTO[0].id
    }
  }, [])

  const valorTotalCursos = useMemo(() => {
    return ehPagamentoRecorrente
      ? 0
      : valoresCursos?.map(x => x.valor ?? 0).reduce((a, b) => a + b, 0) ?? 0
  }, [valoresCursos, ehPagamentoRecorrente])

  const valorTotalOriginalCursos = useMemo(() => {
    if (ehPagamentoRecorrente) {
      return 0
    }
    return (
      valoresCursos
        ?.map(x => x.valorOriginal ?? 0)
        .reduce((a, b) => a + b, 0) ?? 0
    )
  }, [valorTotalCursos, ehPagamentoRecorrente])

  const valorTotalDescontoCursos = useMemo(() => {
    return (
      valoresDescontosCursos
        ?.map(x => x.valor ?? 0)
        .reduce((a, b) => a + b, 0) ?? 0
    )
  }, [valoresDescontosCursos])

  const valorTotalCursosFormatado = useMemo(() => {
    return FuncoesMoeda.formatarMoeda(valorTotalCursos)
  }, [valorTotalCursos])

  const valorTotalDescontoFormatado = useMemo(() => {
    return FuncoesMoeda.formatarMoeda(valorTotalDescontoCursos)
  }, [valorTotalDescontoCursos])

  const pagamentoComCartao = useMemo(
    () => metodoPagamento && metodoPagamento === SELECT_METODOSPAGAMENTO[0].id,
    [metodoPagamento]
  )

  const parcelasCartaoCredito = useMemo(() => {
    if (possuiCarrinho()) {
      const cursoOrdenadoPorParcelas = carrinhoCurso.cursos.sort((a, b) => {
        const modalidadePagamentoCartaoA = a.modalidadesPagamentoTaxaMatricula.filter(
          m => m.formaPagamento === 'CartaoCredito'
        )
        const modalidadePagamentoCartaoB = b.modalidadesPagamentoTaxaMatricula.filter(
          m => m.formaPagamento === 'CartaoCredito'
        )

        if (!modalidadePagamentoCartaoA) {
          return -1
        }

        if (!modalidadePagamentoCartaoB) {
          return 1
        }

        const maiorQuantidadeParcelasA = modalidadePagamentoCartaoA.reduce(
          (i, j) => Math.max(i, j.quantidadeParcelas),
          0
        )
        const maiorQuantidadeParcelasB = modalidadePagamentoCartaoB.reduce(
          (i, j) => Math.max(i, j.quantidadeParcelas),
          0
        )

        if (maiorQuantidadeParcelasA < maiorQuantidadeParcelasB) {
          return 1
        }

        if (maiorQuantidadeParcelasA > maiorQuantidadeParcelasB) {
          return -1
        }

        return 0
      })

      const cursoComMaiorParcelas = cursoOrdenadoPorParcelas[0]

      const parcelas = cursoComMaiorParcelas.modalidadesPagamentoTaxaMatricula
        .filter(modalidade => modalidade.formaPagamento === 'CartaoCredito')
        .sort((a, b) => {
          if (a.quantidadeParcelas < b.quantidadeParcelas) {
            return -1
          }
          if (a.quantidadeParcelas > b.quantidadeParcelas) {
            return 1
          }
          return 0
        })
        .map(modalidade => ({
          id: `${modalidade.quantidadeParcelas}`,
          texto: `${
            modalidade.quantidadeParcelas
          }x de ${FuncoesMoeda.formatarMoeda(
            valorTotalCursos / modalidade.quantidadeParcelas
          )}`
        }))

      return parcelas
    } else {
      const parcelas = cursoEstado.modalidadesPagamentoTaxaMatricula
        .filter(modalidade => modalidade.formaPagamento === 'CartaoCredito')
        .sort((a, b) => {
          if (a.quantidadeParcelas < b.quantidadeParcelas) {
            return -1
          }
          if (a.quantidadeParcelas > b.quantidadeParcelas) {
            return 1
          }
          return 0
        })
        .map(modalidade => ({
          id: `${modalidade.quantidadeParcelas}`,
          texto: `${
            modalidade.quantidadeParcelas
          }x de ${FuncoesMoeda.formatarMoeda(
            valorTotalCursos / modalidade.quantidadeParcelas
          )}`
        }))

      return parcelas
    }
  }, [
    cursoEstado.modalidadesPagamentoTaxaMatricula,
    valorTotalCursos,
    carrinhoCurso
  ])

  const cuponsValidos = useMemo(() => {
    return cupons?.filter(c => c.cupomId !== undefined)
  }, [cupons])

  const primeiroCupomValido = useMemo(() => {
    return cuponsValidos ? cuponsValidos[0] : ({} as CupomValidoCurso)
  }, [cuponsValidos])

  const pagamentoPlanoIsento = useMemo(() => {
    return (
      (ehPagamentoRecorrente &&
        matricula?.planoPagamento?.valorParcelado <= 0) ??
      false
    )
  }, [matricula, ehPagamentoRecorrente])

  const atualizarValorCurso = (cursoId: string, valor: number) => {
    const valorAtual = valoresCursos.find(i => i.cursoId === cursoId)
    definirValoresCursos([
      ...valoresCursos.filter(i => i.cursoId !== cursoId),
      { ...valorAtual, valor }
    ])
  }

  const atualizarValorDescontoCurso = (cursoId: string, valor: number) => {
    definirValoresDescontosCursos([
      ...valoresDescontosCursos.filter(i => i.cursoId !== cursoId),
      { cursoId, valor }
    ])
  }

  const atualizarCuponsUtilizados = (
    temCupom: boolean,
    cupom?: CupomValidoCurso
  ) => {
    if (cupom?.cupomId) {
      const cuponsFiltrados = cupons.filter(c => {
        if (Object.keys(c).length === 0) {
          return false
        }

        return c.cupomId !== cupom.cupomId && c.cursoId !== cupom.cursoId
      })

      if (temCupom) {
        definirCupons([...cuponsFiltrados, cupom])
      } else {
        definirCupons(cuponsFiltrados)
      }
    }
  }

  const acaoSucesso = async (dados: PagamentoMatricula) => {
    if (metodoPagamento !== SELECT_METODOSPAGAMENTO[0].id) {
      try {
        definirCarregandoBoleto(true)
        let boletoGerado: BoletoGerado
        if (possuiCarrinho() && possuiMatriculaCarrinho()) {
          boletoGerado = await Api.GerarBoletoCarrinhoCompra({
            carrinhoCompraId: carrinhoCurso.carrinhoCompraId,
            cupons: cuponsValidos?.map(c => {
              return { cupomId: c.cupomId, cursoId: c.cursoId }
            }),
            diaVencimento: dados.diaVencimento
          })

          definirBoletoGeradoCarrinho(true)
        } else {
          if (!boleto && metodoPagamento === 'Pix') {
            boletoGerado = await Api.GerarBoletoMatricula({
              idMatricula: matricula.id,
              diaVencimento: new Date().getDate().toString(),
              cupomId: primeiroCupomValido?.cupomId,
              pix: 'pix'
            })
          }
          if (!boleto && metodoPagamento === 'Boleto') {
            boletoGerado = await Api.GerarBoletoMatricula({
              idMatricula: matricula.id,
              diaVencimento: dados.diaVencimento,
              cupomId: primeiroCupomValido?.cupomId,
              pix: 'pix-e-boleto'
            })
          }
        }

        definirBoleto(boletoGerado)
        const pagamento = {
          ...dados,
          boletoGerado,
          idCupom: primeiroCupomValido?.cupomId,
          codigoCupom: primeiroCupomValido?.codigoCupom,
          valorDesconto: valorTotalDescontoCursos,
          valorTotal: valorTotalCursos,
          valor: curso.valorTaxaMatricula
        }
        definirPagamento(pagamento)
        definirPagamentoMatriculasCarrinho(pagamento)
      } finally {
        definirCarregandoBoleto(false)
      }
    } else {
      try {
        definirCarregandoFinalizar(true)

        if (ehCursoLivreOuEvento) {
          if (possuiCarrinho() && possuiMatriculaCarrinho()) {
            const payloadCarrinho = {
              carrinhoCompraId: carrinhoCurso.carrinhoCompraId,
              cupons: cuponsValidos?.map(c => {
                return { cupomId: c.cupomId, cursoId: c.cursoId }
              }),
              dadosCartao: {
                nomeImpresso: dados.nomeCartao,
                numero: dados.numeroCartao.replaceAll(' ', ''),
                validade: dados.validadeCartao,
                codigo: dados.codigoSeguranca,
                parcelas: Number(dados.parcelas)
              }
            }

            await Api.AvancarPagamentoCarrinhoComprasCursoLivre(payloadCarrinho)
            modalRef?.current?.abrir()
          } else {
            const payload = {
              matriculaId: matricula.id,
              cupomId: primeiroCupomValido?.cupomId,
              dadosCartao: {
                nomeImpresso: dados.nomeCartao,
                numero: dados.numeroCartao.replaceAll(' ', ''),
                validade: dados.validadeCartao,
                codigo: dados.codigoSeguranca,
                parcelas: Number(dados.parcelas ?? 1)
              }
            }
            await Api.AvancarPagamentoMatriculaCursoLivre(payload)
            modalRef?.current?.abrir()
          }
        } else {
          const payload = {
            matriculaId: matricula.id,
            cupomId: primeiroCupomValido?.cupomId,
            dadosCartao: null
          }

          if (!pagamentoPlanoIsento) {
            payload.dadosCartao = {
              nomeImpresso: dados.nomeCartao,
              numero: dados.numeroCartao.replaceAll(' ', ''),
              validade: dados.validadeCartao,
              codigo: dados.codigoSeguranca,
              parcelas: Number(dados.parcelas ?? 1)
            }
          }

          await Api.AvancarPagamentoMatriculaOutrosCursos(payload)
          modalRef?.current?.abrir()
        }

        const pagamento = {
          ...dados,
          idCupom: primeiroCupomValido?.cupomId,
          codigoCupom: primeiroCupomValido?.codigoCupom,
          valorDesconto: valorTotalDescontoCursos,
          valorTotal: valorTotalCursos,
          valor: curso.valorTaxaMatricula
        }
        definirPagamento(pagamento)
        definirPagamentoMatriculasCarrinho(pagamento)
      } finally {
        definirCarregandoFinalizar(false)
      }
    }

    if (process.env.REACT_APP_GTM) {
      const cursosGTM = []

      if (possuiCarrinho()) {
        carrinhoCurso?.cursos?.forEach(curso => {
          const cuponsCarrinho = cupons?.filter(
            cupom => cupom.cursoId === curso.id
          )
          const cursoGTM = {
            id: v4(),
            name: curso.nome,
            category: curso.modalidade,
            price: curso.valorTaxaMatricula,
            coupon: cuponsCarrinho?.map(cupom => cupom.codigoCupom).toString(),
            position: cursosGTM?.length + 1,
            metric1: valorTotalDescontoCursos,
            metric2: valorTotalCursos - valorTotalDescontoCursos,
            metric3: dados.parcelas,
            metric4: dados.diaVencimento
          }

          cursosGTM.push(cursoGTM)
        })
      } else {
        const cursoGTM = {
          id: v4(),
          name: cursoEstado.nome,
          category: cursoEstado.modalidade,
          price: cursoEstado.valorTaxaMatricula,
          coupon: cuponsValidos?.map(c => c.codigoCupom).join(','),
          position: cursosGTM?.length + 1,
          metric1: valorTotalDescontoCursos,
          metric2: valorTotalCursos - valorTotalDescontoCursos,
          metric3: dados.parcelas,
          metric4: dados.diaVencimento
        }

        cursosGTM.push(cursoGTM)
      }

      const tagManagerArgs = {
        gtmId: 'GTM-NRDM9FL',
        dataLayer: {
          event: 'purchase',
          ecommerce: {
            purchase: {
              actionField: {
                id: v4(),
                option: dados.metodoPagamento,
                step: 'Pagamento do Curso',
                revenue: valorTotalCursos
              },
              products: cursosGTM
            }
          }
        }
      }
      TagManager.initialize(tagManagerArgs)
    }
  }

  const acaoFinalizar = async () => {
    try {
      if (
        metodoPagamento === SELECT_METODOSPAGAMENTO[1].id ||
        metodoPagamento === SELECT_METODOSPAGAMENTO[2].id ||
        (valorTotalCursos < 1 && !ehPagamentoRecorrente)
      ) {
        definirCarregandoFinalizar(true)

        const payload = {
          matriculaId: matricula.id,
          cupomId:
            matricula.pagamentoMatricula?.idCupom ??
            primeiroCupomValido?.cupomId ??
            undefined
        }

        if (ehCursoLivreOuEvento) {
          if (possuiCarrinho() && possuiMatriculaCarrinho()) {
            const payloadCarrinho = {
              carrinhoCompraId: carrinhoCurso.carrinhoCompraId,
              cupons: cuponsValidos?.map(c => {
                return { cupomId: c.cupomId, cursoId: c.cursoId }
              })
            }

            await Api.AvancarPagamentoCarrinhoComprasCursoLivre(payloadCarrinho)
          } else {
            await Api.AvancarPagamentoMatriculaCursoLivre(payload)
          }
        } else {
          await Api.AvancarPagamentoMatriculaOutrosCursos(payload)
        }

        modalRef?.current?.abrir()
      } else {
        formRef.current?.submitForm()
      }
    } finally {
      definirCarregandoFinalizar(false)
    }
  }

  const getBoletoUrl = (url: string) => {
    const sliceCounter =
      url.indexOf('?formato=') > 0 ? url.indexOf('?formato=') : url.length

    if (metodoPagamento === SELECT_METODOSPAGAMENTO[1].id) {
      return url.slice(0, sliceCounter)
    }

    if (metodoPagamento === SELECT_METODOSPAGAMENTO[2].id) {
      return `${url.slice(0, sliceCounter)}?formato=pix`
    }
  }

  useEffect(() => {
    if (
      boleto &&
      boleto.boletoId &&
      metodoPagamento === SELECT_METODOSPAGAMENTO[2].id
    ) {
      const pegarQrCode = async () => {
        if (boleto.boletoId !== undefined) {
          const resposta = await Api.pegarQrCodeBoleto(boleto.boletoId)
          definirPixCopiaECola(resposta.qrCode)
        }
      }

      pegarQrCode()
    }
  }, [boleto, acaoSucesso])

  useEffect(() => {
    if (pixCopiaECola) {
      definirBoleto(prevBoleto => ({
        ...prevBoleto,
        pixCopiaECola
      }))
    }
  }, [pixCopiaECola])

  const renderizarBoleto = useCallback(() => {
    if (boleto) {
      return (
        <Linha>
          <ContainerBoleto>
            {(metodoPagamento === SELECT_METODOSPAGAMENTO[1].id ||
              metodoPagamento === SELECT_METODOSPAGAMENTO[2].id) && (
              <>
                {metodoPagamento === SELECT_METODOSPAGAMENTO[1].id &&
                  metodoPagamento !== SELECT_METODOSPAGAMENTO[0].id && (
                    <>
                      <h5>
                        Boleto gerado com sucesso!
                        <br />
                        Realize o pagamento do boleto em qualquer banco até a
                        data de vencimento
                      </h5>
                      <p>{boleto.linhaDigitavel}</p>
                      <Botao
                        tema="Link"
                        texto={'Copiar Código do Boleto'}
                        type="button"
                        onClick={() => {
                          navigator.clipboard.writeText(boleto.linhaDigitavel)
                        }}
                      />
                    </>
                  )}
                {metodoPagamento === SELECT_METODOSPAGAMENTO[2].id &&
                  metodoPagamento !== SELECT_METODOSPAGAMENTO[0].id && (
                    <>
                      {!pixCopiaECola ? (
                        <>
                          <h5>
                            PIX gerado com sucesso!
                            <br />
                            Realize o pagamento via Pix em qualquer banco em até
                            2 horas
                          </h5>
                          <p>
                            Houve um erro ao gerar o código copia e cola, tente
                            novamente mais tarde!
                          </p>
                        </>
                      ) : (
                        <>
                          <h5>
                            PIX gerado com sucesso!
                            <br />
                            Realize o pagamento via Pix em qualquer banco em até
                            2 horas
                          </h5>
                          <p>{pixCopiaECola}</p>
                          <Botao
                            tema="Link"
                            texto={'Copiar Código do PIX'}
                            type="button"
                            onClick={() => {
                              navigator.clipboard.writeText(pixCopiaECola)
                            }}
                          />
                        </>
                      )}
                    </>
                  )}
              </>
            )}
            {!!boleto.linkBoleto && (
              <PdfViewer url={getBoletoUrl(boleto.linkBoleto)} />
            )}
          </ContainerBoleto>
        </Linha>
      )
    }
  }, [metodoPagamento, boleto])

  const carregarSeEhPagamentoRecorrente = async () => {
    const pagamentoRecorrente = await Api.ObterSeEhPagamentoRecorrente(
      matricula.id
    )
    definirEhPagamentoRecorrente(pagamentoRecorrente.pagamentoRecorrente)
    if (pagamentoRecorrente.pagamentoRecorrente) {
      definirMetedoPagamento(SELECT_METODOSPAGAMENTO[0].id)
    }
  }

  const filtrarMatriculaCurso = (cursoId?: string) => {
    return obterMatriculas().find(x => x.cursoId === cursoId)
  }

  const tituloPagamento = useMemo(() => {
    if (ehCursoLivreOuEvento) {
      return 'Pagamento'
    }

    if (ehPagamentoRecorrente) {
      return 'Cartão Pagamento Recorrente'
    }

    return 'Pagamento da Matrícula'
  }, [ehPagamentoRecorrente, ehCursoLivreOuEvento])

  const schema = useMemo(() => {
    if (pagamentoPlanoIsento) {
      return schemaPagamentoPlanoIsento
    }

    if (ehPagamentoRecorrente) {
      return schemaPagamentoCartaoRecorrencia
    }

    if (metodoPagamento === SELECT_METODOSPAGAMENTO[0].id) {
      return schemaPagamentoCartaoCredito
    }

    if (metodoPagamento === SELECT_METODOSPAGAMENTO[1].id) {
      return schemaPagamentoBoleto
    }

    if (metodoPagamento === SELECT_METODOSPAGAMENTO[2].id) {
      return schemaPagamentoPix
    }
  }, [ehPagamentoRecorrente, metodoPagamento, pagamentoPlanoIsento])

  const cupom100SemRecorrencia = useMemo(() => {
    return (
      !ehPagamentoRecorrente &&
      valorTotalDescontoCursos >= valorTotalOriginalCursos
    )
  }, [
    ehPagamentoRecorrente,
    valorTotalOriginalCursos,
    valorTotalDescontoCursos
  ])

  const exibirCamposCartao = useMemo(() => {
    return (
      (valorTotalCursos > 0 || !pagamentoPlanoIsento) && !cupom100SemRecorrencia
    )
  }, [valorTotalCursos, pagamentoPlanoIsento, cupom100SemRecorrencia])

  const textoIsencao = useMemo(() => {
    return ehPagamentoRecorrente
      ? 'ISENTO: Não há parcelas do Plano de Pagamento a pagar.'
      : ' ISENTO: Não há valores de matrícula a pagar.'
  }, [ehPagamentoRecorrente])

  const pagamentoComCartaoDeCredito = async (dadosCartao: DadosCartao) => {
    try {
      await Api.EnviarPagamentoRecorrente(matricula.id, dadosCartao)
      history.push('/')
    } catch (erro) {
      toast(
        'Erro ao efetivar pagamento recorrente, informe os dados do cartão novamente',
        { type: 'error' }
      )
      modalErroPagRecorrenteRef?.current?.abrir()
    }
  }

  useEffect(() => {
    scrollParaTopo()
  }, [passo])

  useEffect(() => {
    carregarSeEhPagamentoRecorrente()
  }, []) //eslint-disable-line

  useEffect(() => {
    if (metodoPagamento === SELECT_METODOSPAGAMENTO[1].id) {
      const day = dadosIniciais.diaVencimento
        ? dadosIniciais.diaVencimento
        : new Date(new Date().setDate(new Date().getDate() + 2))
            .getDate()
            .toString()

      formRef.current?.setFieldValue('diaVencimento', day)
    }
  }, [metodoPagamento])

  return (
    <>
      {possuiCarrinho() ? (
        carrinhoCurso.cursos.map(c => {
          const matriculaCurso = filtrarMatriculaCurso(c.id)
          return (
            <InfoValoresCurso
              key={c.id}
              curso={c}
              matricula={matriculaCurso}
              acaoAlterarCupom={atualizarCuponsUtilizados}
              boletoGerado={!!boleto}
              pagamentoComCartao={pagamentoComCartao}
              formaPagamento={metodoPagamento as MetodosPagamento}
              acaoAtualizarValorCurso={atualizarValorCurso}
              acaoAtualizarValorDescontoCurso={atualizarValorDescontoCurso}
              cupomId={dadosIniciais.cupomId}
              cupomCodigo={dadosIniciais.codigoCupom}
            />
          )
        })
      ) : (
        <InfoValoresCurso
          key={cursoEstado.id}
          curso={cursoEstado}
          matricula={matricula}
          recorrencia={ehPagamentoRecorrente}
          acaoAlterarCupom={atualizarCuponsUtilizados}
          boletoGerado={!!boleto}
          pagamentoComCartao={pagamentoComCartao}
          formaPagamento={metodoPagamento as MetodosPagamento}
          acaoAtualizarValorCurso={atualizarValorCurso}
          acaoAtualizarValorDescontoCurso={atualizarValorDescontoCurso}
          cupomId={dadosIniciais.cupomId}
          cupomCodigo={dadosIniciais.codigoCupom}
        />
      )}

      <ContainerConteudo>
        {!ehPagamentoRecorrente && (
          <ContainerValorTotalCursos>
            <ContainerInfos>
              <h2>Valor Total Curso(s)</h2>
              <Info>
                <p>Valor Total a Pagar</p>
                <p>{valorTotalCursosFormatado}</p>
              </Info>
              <Info>
                <p>Valor Total Desconto</p>
                <p>{valorTotalDescontoFormatado}</p>
              </Info>
            </ContainerInfos>
          </ContainerValorTotalCursos>
        )}

        <ContainerCadastro>
          <FormUnform
            ref={formRef}
            schema={schema}
            dadosIniciais={dadosIniciais}
            acaoSucesso={acaoSucesso}
            acaoFalha={focarPrimeiroCampoComErro}
          >
            {ehPagamentoRecorrente && (
              <TituloIsentoRecorrencia>
                ISENTO: Não há taxa a pagar por optar pelo pagamento recorrente.
              </TituloIsentoRecorrencia>
            )}

            {exibirCamposCartao ? (
              <>
                <h4>{tituloPagamento}</h4>
                <Linha>
                  <ContainerMedio>
                    {valorTotalCursos > 0 && (
                      <SelectUnform
                        id="slc_metodo_pagamento"
                        name="metodoPagamento"
                        label="Selecione a Forma de Pagamento"
                        obrigatorio
                        opcoes={
                          ehCursoLivreOuEvento
                            ? SELECT_METODOSPAGAMENTO.filter(
                                x => x.id !== 'Pix'
                              )
                            : SELECT_METODOSPAGAMENTO
                        }
                        valorAlterado={valor => {
                          definirMetedoPagamento(valor.id)
                          definirNumeroCartao('')

                          formRef.current?.setErrors({})
                        }}
                        disabled={!!boleto}
                      />
                    )}
                    {metodoPagamento === SELECT_METODOSPAGAMENTO[0].id && (
                      <>
                        <LinhaBandeiras>
                          <ContainerBandeiras
                            selecionado={
                              !!numeroCartao.match(regExpVisa) &&
                              !numeroCartao.match(regExpElo)
                            }
                          >
                            {IconeCartaoVisa}
                          </ContainerBandeiras>
                          <ContainerBandeiras
                            selecionado={!!numeroCartao.match(regExpMaster)}
                          >
                            {IconeCartaoMaster}
                          </ContainerBandeiras>
                          <ContainerBandeiras
                            selecionado={!!numeroCartao.match(regExpAmex)}
                          >
                            {IconeCartaoAmex}
                          </ContainerBandeiras>
                          <ContainerBandeiras
                            selecionado={!!numeroCartao.match(regExpElo)}
                          >
                            {IconeCartaoElo}
                          </ContainerBandeiras>
                          <ContainerBandeiras
                            selecionado={!!numeroCartao.match(regExpHiper)}
                          >
                            {IconeCartaoHiper}
                          </ContainerBandeiras>
                          <ContainerBandeiras
                            selecionado={!!numeroCartao.match(regExpJcb)}
                          >
                            {IconeCartaoJcb}
                          </ContainerBandeiras>
                          <ContainerBandeiras
                            selecionado={!!numeroCartao.match(regExpDiners)}
                          >
                            {IconeCartaoDiners}
                          </ContainerBandeiras>
                        </LinhaBandeiras>
                        <CartaoCreditoInputUnform
                          id="ipt_numero_cartao"
                          name="numeroCartao"
                          label="Número do Cartão"
                          obrigatorio
                          onChange={e => {
                            const valor = e.target.value.replaceAll(' ', '')
                            definirNumeroCartao(valor)
                          }}
                        />
                        <InputUnform
                          id="ipt_nome_cartao"
                          name="nomeCartao"
                          label="Nome Conforme Consta no Cartão"
                          obrigatorio
                        />
                        <Linha>
                          <ContainerMedio>
                            <InputMesAnoUnform
                              id="ipt_validade_cartao"
                              name="validadeCartao"
                              label="Validade do Cartão"
                              placeholder="mm/aaaa"
                              formatoAno="AAAA"
                              obrigatorio
                            />
                          </ContainerMedio>
                          <ContainerMedio>
                            <InputUnform
                              id="ipt_codigo_seguranca"
                              name="codigoSeguranca"
                              label={
                                <>
                                  Código de Segurança
                                  <IconeLabel
                                    data-tip="O código de segurança, ou CVV, refere-se ao código de 3 dígitos exibido na frente ou no verso do seu cartão."
                                    data-for="tooltip-cvv"
                                  >
                                    {IconeInformacao}
                                  </IconeLabel>
                                </>
                              }
                              obrigatorio
                              maxLength={4}
                            />
                            <Tooltip
                              id="tooltip-cvv"
                              place="right"
                              className="tooltip-ipgs"
                              textColor={Cores.PRETO}
                              backgroundColor={Cores.CINZA_1_CLARO}
                            />
                          </ContainerMedio>
                        </Linha>
                      </>
                    )}
                  </ContainerMedio>
                  <ContainerMedio>
                    {!ehPagamentoRecorrente && (
                      <>
                        {metodoPagamento === SELECT_METODOSPAGAMENTO[0].id ? (
                          <SelectUnform
                            id="slc_parcelas"
                            name="parcelas"
                            label="Selecione a Quantidade de Parcelas"
                            obrigatorio
                            iconeFechar
                            opcoes={parcelasCartaoCredito}
                          />
                        ) : (
                          <>
                            <Linha>
                              {metodoPagamento !== 'Pix' && (
                                <ContainerMedio>
                                  <SelectUnform
                                    id="slc_dia_vencimento"
                                    name="diaVencimento"
                                    label="Dia de Vencimento"
                                    placeholder="Selecione"
                                    obrigatorio
                                    opcoes={SELECT_DIAS_DIAS_BOLETO_AUTOMATICO}
                                    disabled
                                  />
                                </ContainerMedio>
                              )}
                              {!boleto && metodoPagamento === 'Boleto' && (
                                <ContainerBotaoBoleto>
                                  <Botao
                                    texto="Gerar o Boleto"
                                    tema="Secundario"
                                    carregando={carregandoBoleto}
                                  />
                                </ContainerBotaoBoleto>
                              )}
                              {!boleto && metodoPagamento === 'Pix' && (
                                <ContainerBotaoPix>
                                  <Botao
                                    texto="Gerar o Pix"
                                    tema="Secundario"
                                    carregando={carregandoBoleto}
                                  />
                                </ContainerBotaoPix>
                              )}
                              {boleto && (
                                <ContainerBotaoBoleto>
                                  <BotaoStep
                                    texto="Concluir"
                                    tamanho={'S'}
                                    textoNegrito="Matrícula"
                                    type="button"
                                    carregando={carregandoFinalizar}
                                    disabled={
                                      (metodoPagamento ===
                                        SELECT_METODOSPAGAMENTO[1].id ||
                                        metodoPagamento ===
                                          SELECT_METODOSPAGAMENTO[2].id) &&
                                      !boleto &&
                                      valorTotalCursos > 0
                                    }
                                    onClick={acaoFinalizar}
                                    textoTooltip={
                                      (metodoPagamento ===
                                        SELECT_METODOSPAGAMENTO[1].id ||
                                        metodoPagamento ===
                                          SELECT_METODOSPAGAMENTO[2].id) &&
                                      !boleto &&
                                      valorTotalCursos > 0
                                        ? 'É necessário realizar a geração do documento para prosseguir com o pagamento'
                                        : undefined
                                    }
                                  />
                                </ContainerBotaoBoleto>
                              )}
                            </Linha>
                          </>
                        )}
                      </>
                    )}
                  </ContainerMedio>
                </Linha>
              </>
            ) : (
              <TituloIsento>{textoIsencao}</TituloIsento>
            )}
            {renderizarBoleto()}
          </FormUnform>
          <ContainerAcoes>
            <BotaoStep
              invertido
              texto="Voltar para"
              textoNegrito={
                ehCursoLivreOuEvento ? 'Dados Básicos' : 'Termos e Condições'
              }
              tema="Secundario"
              disabled={carregandoVoltar}
              onClick={() => {
                definirCarregandoVoltar(true)
                voltarWizard()
              }}
            />
            {!boleto && (
              <BotaoStep
                texto="Concluir"
                textoNegrito="Matrícula"
                type="button"
                carregando={carregandoFinalizar}
                disabled={
                  (metodoPagamento === SELECT_METODOSPAGAMENTO[1].id ||
                    metodoPagamento === SELECT_METODOSPAGAMENTO[2].id) &&
                  !boleto &&
                  valorTotalCursos > 0
                }
                onClick={acaoFinalizar}
                textoTooltip={
                  (metodoPagamento === SELECT_METODOSPAGAMENTO[1].id ||
                    metodoPagamento === SELECT_METODOSPAGAMENTO[2].id) &&
                  !boleto &&
                  valorTotalCursos > 0
                    ? 'É necessário realizar a geração do documento para prosseguir com o pagamento'
                    : undefined
                }
              />
            )}
          </ContainerAcoes>
        </ContainerCadastro>
      </ContainerConteudo>
      <ModalPagamentoMatricula ehBoleto={boleto} backdrop ref={modalRef} />
      <ModalPagamentoCartaoRecorrente
        ref={modalErroPagRecorrenteRef}
        backdrop
        id="modal-pagamento"
        acaoPrimaria={pagamentoComCartaoDeCredito}
      />
    </>
  )
}
